// import gsap from "gsap";
// import { useRef } from "react";

const ProcessCard = ({ num, header, body }) => {
//   let mouseFollow = useRef();

//   function mouseEnterEvent(e) {
//     gsap.to(mouseFollow.current, {
//       display: "none",
//       duration: 0.2,
//     });
//   }
//   function mouseEnterLeave(e) {
//     gsap.to(mouseFollow.current, {
//       display: "block",
//       duration: 0.2,
//     });
//   }

//   function mouseMoveEvent(e) {
//     // Get the target
//     const target = e.target;

//     // Get the bounding rectangle of target
//     const rect = target.getBoundingClientRect();

//     // Mouse position
//     const x = e.clientX - rect.left;
//     const y = e.clientY - rect.top;
//     gsap.to(mouseFollow.current, {
//       left: x,
//       top: y,
//       duration: 0.3,
//     });
//   }

  return (
    <div
      className="test-out w-full md:max-w-[21em] relative overflow-hidden hover:shadow-2xl shadow-blue-500 rounded-md"
    //   onMouseMove={mouseMoveEvent}
    >
      {/* <div
        ref={mouseFollow}
        className="absolute w-[3em] h-[3em] z-0 highlight"
      ></div> */}

      <div className="backdrop-blur-3xl w-full h-full p-7 md:p-3">
        <div className="relative circle w-[2.3em] h-[2.3em] rounded-full bg-primary-color-500 grid place-items-center z-10">
          <p className="relative text-md font-semi-bold z-10">{num}</p>
        </div>

        <h2 className="relative font-semi-bold my-4 z-10">{header}</h2>
        <p className="relative content font-normal text-[#D4D4D4]  text-[16px] z-10">
          {body}
        </p>
      </div>
    </div>
  );
};
export default ProcessCard;
