// Utility function to create a list of links
import React from "react";
import { Link } from "react-router-dom";

const FooterLinkList = ({ items }) => (
  <ul className="list-none m-0 text-left space-y-2.5">
    {items.map((item, index) => (
      <li
        key={index}
        className="text-white linkButton text-sm font-normal font-['Noto Sans'] leading-tight tracking-tight"
      >
        <Link to={item.path}>
          {item.label}
        </Link>
      </li>
    ))}
  </ul>
);

export default FooterLinkList;
