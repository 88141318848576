import React, { useEffect, useState } from "react";
import Header from "./headerFooter/Header";
import Footer from "./headerFooter/Footer";
import { Outlet } from "react-router";
import { Link, useLocation } from "react-router-dom";
import TabletMobileNav from "./headerFooter/Tablet-Mobile-Nav";
import "../App";
import MobileNavStateContext from "../context/navContext";


const BootCampBanner = () => {
  return <div className='font-noto px-[clamp(1em,8vw,5em)] w-full bg-blue-600 h-[8vh] flex items-center justify-between'>
    <p className="pr-2 lg:text-lg text-sm font-noto">TO BE PART OF OUR BOOTCAMP PROGRAM</p>
    <button className="bg-white text-blue-700 px-4 py-2 rounded-lg">
    <Link to={'bootcamp'}>Register</Link></button>
  </div>
}


const Layout = () => {
  const [navIsOpened, setNavVisibility] = useState(false);
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollToTop(window.scrollY > 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <MobileNavStateContext.Provider value={{ navIsOpened, setNavVisibility }}>
      <div
        className={`overflow-hidden w-full min-w-screen bg-bg-color bg-gradient-to-t from-[transparent] via-[transparent] via-90% to-primary-color-200 text-white font-noto relative`}
      >
        {location.pathname === '/' && <BootCampBanner />}
        <Header />
        {navIsOpened ? <TabletMobileNav /> : null}
        <Outlet />
        <Footer />
        {showScrollToTop && (
          <button
            className={`btn scroll-to-top-btn ${showScrollToTop ? "show" : ""}`}
            onClick={scrollToTop}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line x1="12" y1="19" x2="12" y2="5"></line>
              <polyline points="5 12 12 5 19 12"></polyline>
            </svg>
          </button>
        )}
      </div>
    </MobileNavStateContext.Provider>
  );
};

export default Layout;
