import React, { useState } from "react";

const SocialList = ({ icons, descriptions }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <div className="flex items-center ml-0.5 gap-3">
      {icons.map((icon, index) => (
        <div
          key={index}
          className="cursor-pointer relative"
          onMouseEnter={() => setHoveredIndex(index)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          <a href={icon.path} target="_blank" rel="noopener noreferrer">
            {icon.icon}
          </a>

          {hoveredIndex === index && (
            <div className="text-white text-sm absolute top-8 left-0 right-0 p-2 opacity-75">
              {descriptions[index]}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default SocialList;
