import React from "react";
import Card from "./Card";

const CardList = () => {
  let isOpen = true;

  return (
    <div class="home_cards_container">
      <div  className="container flex_wrapper flex_stretch">
        <Card
          imageUrl="/images/about_us/about_us.jpg"
          cardTitle="Internship at JVEC Solutions"
          cardLink="/internship"
          open={isOpen}
        />
        <Card
          imageUrl="/images/about_us/opening.jpg"
          cardTitle="Openings at JVEC Solutions"
          cardBody={"Unleash your potential and embark on a journey of unparalleled growth with us, an ICT company where excellence reigns supreme. Join our dynamic team and experience the transformative power of a culture that empowers, inspires, and rewards excellence. Here, you'll find a world of opportunities to nurture your skills, collaborate with brilliant minds, and leave an indelible mark on the future of technology. Embrace the challenge, unleash your brilliance, and discover the premium benefits of a career at our company."}
          cardLink="/openings"
          open={!isOpen}
        />
      </div>
    </div>
  );
};

export default CardList;
