import React, { useEffect } from "react";

export const Openings = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div className="relative lg:pt-36 pt-20">
            <div className="bg-[url(/public/images/openings/openings_bg.png)] bg-cover bg-center bg-no-repeat min-h-[40vh] min-h-[50vh] lg:min-h-[80vh] filter brightness-50"></div>
            <div className="absolute w-full flex flex-col lg:pt-20 bottom-[50%] top-[50%] font-noto items-center justify-center text-center text-white">
                <img src='./images/padlock_image.png' className="mb-2" alt="" style={{ width: '18%' }} />
                <div className="w-[80%]">
                    <h2 className="font-bold lg:text-[30px] text-[24px] mb-2">NO OPENINGS AT THE MOMENT</h2>
                    <h3 className="lg:text-[20px] text-[14px] lg:pb-20 ">
                        Hi there! There are no available openings at the moment, please kindly check back. Thank you.
                    </h3>
                </div>
            </div>
        </div>
    );
};

export default Openings;