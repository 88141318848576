import React, { useEffect } from 'react';
import BrandCarousel from '../components/brandCarousel/BrandCarousel';
import '../styles/Home.css'
import VerticalCarousel from '../components/verticalCarousel/VerticalCarousel';
import HeroPage from './Hero';
import VideoPlayer from '../components/videoPlayer/VideoPlayer';
import Section from '../components/section/Section';
import TestimonialCarousel from '../components/testimonialCarousel/TestimonialCarousel';
import testimonials from '../components/testimonialCarousel/testimonials';
import Aos from 'aos';
import 'aos/dist/aos.css'
import HomeAboutUs from './HomeAboutUs';
import { Link } from 'react-router-dom';



const Home = () => {

  useEffect(()=>{
    Aos.init({duration: 1500})
    window.scrollTo(0, 0);
  
  }, []);

  const features = [
    {
      icon: '/images/home/icons/percentage-square.png',
      iconActive: '/images/home/icons/percentage-square-blue.png',
      title: '100% Client Satisfaction',
      description: 'Our unwavering commitment to excellence empowers us to deliver impeccable services, consistently exceeding expectations within every deadline.'
    },
    {
      icon: '/images/home/icons/award.png',
      iconActive: '/images/home/icons/award-blue.png',
      title: '6+ Years of Excellence',
      description: 'Distinguished by our relentless pursuit of perfection, we seamlessly provide unparalleled services that redefine industry standards.'
    },
    {
      icon: '/images/home/icons/star.png',
      iconActive: '/images/home/icons/star-blue.png',
      title: '5-Star Customer Support',
      description: 'At the heart of our company beats a dedication to unmatched customer support, where we stand by our clients at every turn, offering unwavering assistance throughout their journey with us.'
    },
  ];
  
  return (
    <div className="homepage">
      <HeroPage />
      <section className="brand">
        <BrandCarousel />
      </section>

      <main id="main-content">
        <div className="flex_wrapper flex_start container px-7">
          <div className="text_col">
            <h2 data-aos='fade-up' data-aos-delay='100' >Why we are your Trusted ICT Consultant.</h2>
            <p data-aos='fade-up' data-aos-delay='200'>We unleash the potential of Technology for your Business</p>
            <Link
            to="services"
            smooth={true}
            duration={500}
            className="btn btn_lg"
            data-aos='fade-up' 
            data-aos-delay='300'
            data-aos-anchor-placement="top-bottom" 
            data-aos-easing="ease-out-cubic"
          >
            Learn more
          </Link>

            <div>
        </div>
          </div>
          <div className="vertical_slide">
            <VerticalCarousel features={features} />
          </div>
        </div>
      </main>
      <div className=" middle_section">
        <div className="container flex_wrapper flex_start">
          <div className="video_wrapper">
            <VideoPlayer  />

          </div>
          <div className="text_col">
            <h2 data-aos='fade-down' data-aos-delay='100' data-aos-offset="100">JVEC — Pioneering Excellence in ICT Solutions</h2>
            <p data-aos='fade-up' data-aos-delay='100'>
            Excellence isn’t a goal, it’s our DNA; our relentless pursuit of excellence is the driving force behind our success. Excellence is our legacy, and we are proud to carry it forward. Watch the video to see how we are innovating to keep businesses on top of their games.
            </p>
            <Link 
              data-aos='fade-in' 
              data-aos-delay='100'
              smooth={true}
              duration={500}
              data-aos-anchor-placement="top-bottom" 
              data-aos-easing="ease-out-cubic"
              to="services" className="btn btn_lg ">
                Get Started
            </Link>
          </div>
        </div>
      </div>
      <Section/>

      <div className="testimonial_home">
        <h3 data-aos='fade-down' data-aos-delay='100' data-aos-offset="100" className='text-60 '>Building Trust</h3>
        <p data-aos='fade-up' data-aos-delay='200' className="text-center text-30 mb-10">Trust earned and preserved</p>
        <div className="container">
          <TestimonialCarousel testimonials={testimonials} />
        </div>
      </div>

      <HomeAboutUs />
        
            
    </div>
  );
};

export default Home;
