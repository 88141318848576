const FaqsData = [
  {
    question: "How will the classes be held?",
    answer:
      "Live video classes will be held on Google Meet, and the class will also be recorded for reference purposes. There will also be a Slack channel for class discussions.",
  },
  {
    question: "Is this course certified and how valid is the certificate?",
    answer:
      "At the end of the training, participants will be awarded a training certificate (an electronic certificate) after the completion of projects and final assessment. Our Certificate is valid; we’re duly registered with the Corporate Affairs Commission, Nigeria.",
  },
  {
    question: "Can I take multiple classes?",
    answer: "Yes, but it all depends on your learning ability and speed. We would advise you to start with 1 class. If you are fast at learning, you can take 2 classes.",
  },
  {
    question: "How much is the training fee?",
    answer: "You can request the fee by filling out the form on this page.",
  },
  {
    question: "Are there options to pay in installments?",
    answer: "Yes, you can pay in 2 to 5 installments.",
  },
  {
    question: "Are there discounts for taking multiple courses?",
    answer: "Yes, discounts apply to multiple courses.",
  },
];

export default FaqsData;
