import gsap, { Expo } from "gsap";
import { useEffect, useRef } from "react";
import ContactCard from "./components/contactCard";
import ContactUs from "../../components/ContactUs";

const ContactUsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const imageRef = useRef();
  const contactInfo = [
    {
      "icon":"/images/contact_us/location.svg",
      "title":"Visit Us",
      subtitle: "Find us at",
      body: "12 Rev. Ogunbiyi Street, Off Oba Akinjobi, Ikeja GRA, Lagos, Nigeria."
    },
    {
      "icon":"/images/contact_us/call.svg",
      "title":"Call Us",
      subtitle: "Round the clock support",
      body: "+234-0814-097-9054"
    },
    {
      "icon":"/images/contact_us/message-2.svg",
      "title":"Contact Us",
      subtitle: "Feel free to shoot us a mail",
      body: "hello@jvecsolutions.com"
    }
  ]

  function onload(event) {
    gsap.from(imageRef.current, {
      height: 0,
      duration: 1.3,
      ease: Expo.easeInOut,
    });
  }

  return (
    <main>
      {/* bg-[url(/public/images/contact_us/thumbnail_contactHero.png)] */}
      <header className="h-screen w-full flex items-start relative">
        <div className="w-full h-full flex justify-center items-center relative">
          <img
            onLoad={onload}
            ref={imageRef}
            src="/images/contact_us/thumbnail_contactHero.png"
            alt="contact headerImage"
            className="absolute centerXY w-full object-cover h-[75vh] mt-[2vh]"
          />
          <h1 className="text-[clamp(2.7em,6vw,4.5em)] text-center z-50 mx-[clamp(1em,8vw,5em)]">
            Contact Us
          </h1>
        </div>
      </header>
      <div className="flex justify-around w-full px-[clamp(1em,8vw,5em)] flex-col gap-y-10 md:flex-row md:gap-0 items-center pb-28">
        {
          contactInfo.map((item)=><ContactCard key={item.title} icon={item.icon} title={item.title} body={item.body} subTitle={item.subtitle} />)
        }
      </div>
      <h1 className="text-white text-[clamp(1.9em,7vw,3.2em)] px-[clamp(1em,8vw,5em)] text-center">
      Pay us a Visit
      </h1>
      <p className="content font-normal text-[#D4D4D4] px-[clamp(1em,8vw,5em)] text-center text-[clamp(0.7em,3vw,1em)] mb-10">
      Get directions on Google Map
      </p>
      <iframe
        title="JVEC solution's on map location"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d360.16104357424354!2d3.347614350575193!3d6.5799620350026915!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b854b8c4d8dad%3A0x57d41ca60e6d4201!2sJVEC%20SOLUTIONS!5e0!3m2!1sen!2sng!4v1702026749787!5m2!1sen!2sng"
        // width="800"
        // height="600"
        className="w-full h-[30em]"
        // style="border:0;"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>

      <ContactUs className="px-[clamp(1em,8vw,5em)] justify-between" textClass="md:max-w-[30em] "/>
      <div className="mb-32"></div>
    </main>
  );
};
export default ContactUsPage;
