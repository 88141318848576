import React from 'react'
import SectionItem from './SectionItem';




const Section = () => {
  const hasLink = true;
  const urlLink = '/services';

  return (
    <div id='section-id'>
        <SectionItem
            imageUrl = './images/home/full_3d_ball.svg'
            headerText = "Premium Solutions"
            paragraphText="In today's dynamic digital landscape, where technology is the cornerstone of success, our ICT Consulting firm emerges as a beacon of innovation, empowering businesses to transcend the boundaries of the ordinary and embrace the extraordinary. With our unwavering commitment to premium quality, we seamlessly integrate cutting-edge ICT solutions into your business fabric, propelling you towards a future of limitless possibilities, where efficiency, productivity, and growth know no bounds."
            link = {!hasLink}
        />
        <SectionItem
            imageUrl = './images/home/open_3d_ball.svg'
            headerText = "We are preferred"
            paragraphText="Brands choose us for the unparalleled fusion of innovation and reliability. We stand as a beacon of trust, translating visions into exceptional digital experiences. Our adept team of experts craft tailor-made solutions, ensuring every brand journey is unique and impactful."
            hasLink = {hasLink}
            linkUrl={urlLink}

        />
    
    </div>
  )
}

export default Section;
