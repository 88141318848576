import './CoreValues.css'

function  ReusableValues ({image, h5Text, text}){

  return(
    <div className={'bg-[#212121] rounded-lg font-noto p-10 relative'}>
        <div className={`rounded-full w-[2.5em] h-[2.5em] ${image? "":"blur-class"} z-50 relative`}>
            {image ? <img src={image} alt="ICT training courses"/> : <div className='blur'></div>}
        </div>

        <h2 className="text-lg mt-6 mb-2 z-50 relative">{h5Text}</h2> 
        <p className="font-normal text-sm text-[#A3A3A3] z-50 relative">{text}</p>   
    </div>
  )
}

export default ReusableValues