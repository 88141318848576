import React, { useEffect } from 'react';
// import TypewriterBootCamp from "typewriter-effect";
import '../../styles/Bootcamp.css';
import Aos from 'aos';
import "aos/dist/aos.css";
import { Link } from 'react-router-dom';
import AppFaq from '../Training/trainingPage/Faq';
import BootCampFaqsData from './BootCampFAQs';


const BootCamp = () => {

  useEffect(()=>{
  Aos.init({duration: 2000})
  window.scrollTo(0, 0);
  
  },[])
  return (
    <main className="min-h-screen bg-black font-noto mb-20">
    <header className="lg:min-h-screen pt-20 flex justify-center items-center">
        <div className=" w-full relative min-h-[40vh] md:min-h-[80vh] h-[30vh] bg-cover bg-center bg-[url(/public/images/boot_camp/bootcamp.webp)] flex justify-center items-center flex-col px-[clamp(1em,8vw,5em)]">
        <h1 className='bootcamp_heading text-[clamp(2.9em,2vw,1.25em)] lg:text-[clamp(3.9em,2vw,1.25em)]'>The Switch-to-Tech Bootcamp.</h1>
          <div className="font-normal text-[clamp(2.9em,2vw,1.25em)] hero_subtext md:text-[clamp(0.9em,2vw,1.25em)] lg:text-[clamp(1.9em,2vw,1.25em)] text-center bootcamp_subhead">
          Start your lucrative career in Tech
        </div>
        </div>
      </header>
      <section
        className="w-100% lg:px-[clamp(1em,8vw,5em)] mt-20 min-h-[75vh] flex flex-col justify-center"
      >
        <div className="text-center px-10 lg:px-0 mt-10 mb-32 text-lg flex flex-col gap-4">
          <p>Since the bloom of the Internet revolution, the emergence of Technology has changed the way humans live in the 21st century.
          </p>
          <p>Millions of jobs have been created by the Tech industry and new jobs are still being created as new technologies emerge.
          </p>
          <p>The Switch-to-Tech Bootcamp has been designed for people aspiring to begin a lucrative career in Technology.
          </p>
          <p>The Bootcamp is 100% practical as there would be assignments and projects for students to build confidence. Also, the facilitators will be sharing their wealth of experience amassed over the years, thus exposing students to what to expect in real-life work scenarios.
          </p>
          <p>The training is very intensive and will prepare a solid foundation for newbies to build a solid career in Tech.
          </p>
          <p>
          This is not just another Bootcamp as we have put in plans to absorb outstanding students into an advanced training program which translates into a 6-month paid internship with us.

          </p>
        </div>
        
        <div className="schedule-section mx-10 lg:mx-[120px] mb-20">
        <div className= "text-center mb-[50px]">
          <h1 className= " sm:text-lg md:text-xl text-2xl lg:text-2xl xl:text-3xl">Cohort Schedule</h1>
          <h1 className= "font-normal text-[#D4D4D4]  text-center text-[clamp(0.7em,3vw,1em)]">Choose any convenient Class</h1>
        </div>

        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[20px]">
        <div className="bg-[#1E1E1E] text-white font-noto h-[350px] rounded-xl pt-[50px] pb-[50px]  text-center">
            <p>Duration</p>
            <b className= "lg:text-[36px] text-[26px] sm:text-lg md:text-xl lg:text-2xl xl:text-3xl" >2 Weeks</b>
            <p className= "mt-[60px] ">Date:</p>
            <div className="flex flex-wrap justify-center">
                <b className="lg:text-[16px] text-[16px] sm:text-lg md:text-xl lg:mx-auto mx-[10px] px-10 lg:px-[20px] text-center">
                18th December, 2023 –
                </b>
                <b className="lg:text-[16px] text-[16px] sm:text-lg md:text-xl lg:mx-auto mx-[10px] px-10 lg:px-[20px] text-center">
                29th December, 2023
                </b>
              </div>
          </div>

          <div className="bg-[#1E1E1E] text-white font-noto lg:h-[350px] h-[350px] rounded-xl pt-[50px] pb-[50px]  text-center"
          style={{
                paddingTop:"50px",
              }}>
              <b className= "lg:text-[36px] text-[26px] sm:text-lg md:text-xl lg:text-2xl xl:text-3xl " >Monday - Friday</b>
              <p className= "mx-[10px] mt-[40px]" >
                Morning Class: 
                </p>
                <p
                className="mx-[10px] text-[14px] font-noto font-bold lg:text-[16px] mb-[20px]"
              >10am - 1pm
              </p>
              <p className="">Afternoon Class:</p>
              <p className="mx-auto font-noto mb-1 text-[14px] font-bold  text-center mb-[20px]">
              2:30pm – 5pm
              </p>
              <p className= "text-[#A3A3A3]">Evening Class:</p>
              <p className=" text-[#A3A3A3] mx-auto font-noto mb-1 text-[14px] font-bold  text-center mb-[20px]">
              Not Available
              </p>

          </div>
          <div className="bg-[#1E1E1E] text-white font-noto h-[350px] rounded-xl pt-[50px] pb-[50px]  text-center">
              <b className= "text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl text-[26px] lg:text-[36px]" >Saturday</b>
              <p className='mx-[10px] mt-[40px]'>Morning Class:</p>
              <p className="mx-auto font-noto mb-1 text-[14px] font-bold  text-center mb-[20px]">
              10am – 12:30pm
              </p>
              <p className=''>Afternoon Class:</p>
              <p className="mx-auto font-noto mb-1 text-[14px] font-bold  text-center mb-[20px]">
              2:30pm – 5pm
              </p>
              <p className= "text-[#A3A3A3]">Evening Class:</p>
              <p className="text-[#A3A3A3] mx-auto font-noto mb-1 text-[14px] font-bold  text-center mb-[20px]">
              Not Available
              </p>
          </div>
        </div>

        <div className=" mt-20 border-[1px] border-primary-color-200 px-10 rounded-md">
        
          <AppFaq faqsData={BootCampFaqsData} />
        </div>

        <div className="mt-10 flex w-full justify-center mb-2">
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to="https://forms.gle/CnyyWwCh8KTQ5au49"
              className="btn w-full text-center max-w-[15rem]"
            >
              Apply
            </Link>
          </div>
      </div>
      </section>
      </main>
  );
};

export default BootCamp;
