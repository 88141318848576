const serviceProcess = [
    {
      num: 1,
      header: "Strategic Consultation",
      body: "We kick off with a strategic consultation to understand your brand, goals, and audience.",
    },
    {
      num: 2,
      header: "Collaboration",
      body: "At JVEC, We collaborate closely to ensure the end result meets your expectations and makes you happy.",
    },
    {
      num: 3,
      header: "Delivery",
      body: "Our delivery rate is 100%; our clients deserve nothing less.",
    },
  ]
export default serviceProcess