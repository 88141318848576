import React from "react";
import { Link } from "react-router-dom";

const LinkList = ({ items, urls, dismissNav }) => (
  <ul className="list-none m-0 text-left space-y-2.5">
    {items.map((item, index) => (
      <li
        onClick={dismissNav ? dismissNav : null}
        key={index}
        className="text-white linkButton text-sm font-normal font-['Noto Sans'] leading-tight tracking-tight"
      >
        <Link to={urls[index] || `/services/${item.toLowerCase().split(' ')[0]}`}>
          {item}
        </Link>
      </li>
    ))}
  </ul>
);

export default LinkList;
