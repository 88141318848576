import React from 'react';
import { Link } from 'react-router-dom';
import './Card.css';

const Card = ({ cardTitle, cardBody, imageUrl, cardLink, open }) => {
  return (
    <div className='home_card'>
      <div className="card_image">
        <img src={imageUrl} alt={cardTitle} />
      </div>
      <h3>{cardTitle}</h3>
      {cardBody}
      {open && (
        <>
          <p> At JVEC Solutions, We believe in:</p>
          <ul>
            <li>Diligence | We believe that Hard-work has no substitute.</li>
            <li>Excellence | We believe that Excellence is prime to stand out from the crowd.</li>
            <li>Integrity | We believe that nothing sells better like keeping to your words.</li>
          </ul>
        </>
      )}
      {open && <h4>Who you are</h4>}
      {open && (
        <ul>
          <li>You’re a Tech enthusiast.</li>
          <li>You’re able to think out of the box.</li>
          <li>You are familiar with the Tech industry.</li>
        </ul>
      )}
      <Link to={cardLink} className='btn_arr'>
        Learn more &rarr;
      </Link>
    </div>
  );
};

export default Card;
