import React, { useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./TestimonialCarousel.css";

const TestimonialCarousel = ({ testimonials }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 12000,
    beforeChange: (current, next) => setActiveIndex(next),
    ref: sliderRef,
  };

  const handleThumbnailClick = (index) => {
    setActiveIndex(index);
    sliderRef.current.slickGoTo(index);
  };

  return (
    <div className="testimonial-carousel-container">
      <div className="carousel">
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <div key={index}>
              <div className="testimonial-item">
                <div className="testimonial-content">
                  <div className="testimonial-image">
                    <img src={testimonial.image} alt={testimonial.desc} />
                  </div>
                  <p
                    className={
                      activeIndex === index
                        ? "testimonial-text active"
                        : "testimonial-text"
                    }
                  >
                    {testimonial.testimonial}
                  </p>
                  <p
                    className={
                      activeIndex === index ? "author active" : "author"
                    }
                  >
                    {testimonial.author}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <div className="sidebar">
        <div className="thumbnail-wrap">
          {testimonials.map((testimonial, index) => (
            <img
              key={index}
              src={testimonial.image}
              alt={testimonial.desc}
              onClick={() => handleThumbnailClick(index)}
              className={
                activeIndex === index ? "thumbnail active" : "thumbnail"
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TestimonialCarousel;
