import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ProcessCard from "./components/processCard";
import serviceProcess from "./serviceProcess";
import ContactUs from "../../components/ContactUs";
export const MainServices = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
    let processContent = serviceProcess.map((elem) => (
        <ProcessCard
          key={elem.num}
          num={elem.num}
          header={elem.header}
          body={elem.body}
        />
      ));
  return (
    <main className="min-h-screen w-full bg-black font-noto mb-6">
      <header className="w-full bg-black pt-24 mb-20 flex items-center">
        <div className="w-full relative min-h-[40vh] md:min-h-[80vh] h-[30vh] data-te-lazy-load-init data-te-lazy-src bg-cover bg-center bg-[url(/public/images/services/test.jpg)] flex justify-center items-center flex-col px-[clamp(1em,8vw,5em)]">
          <h1 className="text-[clamp(2.7em,6vw,4.5em)] max-w-[95%] md:max-w-[70%] lg:max-w-[55%] text-center">
            Our Services
          </h1>
          <p className=" font-normal px-10 text-[clamp(0.9em,2vw,1.25em)] text-center">
            We are reliable partner, we take your business to its peak
          </p>
        </div>
      </header>
      <h1 className="text-white text-[clamp(1.9em,7vw,3.2em)] px-[clamp(1em,8vw,5em)] text-center">
        Our Core Proficiency
      </h1>
      <p className="content font-normal text-[#D4D4D4] px-[clamp(1em,8vw,5em)] text-center text-[clamp(0.7em,3vw,1em)]">
        experience unrivaled quality
      </p>
      <section className="grid grid-col-1 place-content-center md:grid-cols-2 px-[clamp(1em,8vw,5em)] mt-16 gap-12 lg:gap-20 pb-20">
        <div className="flex justify-center items-center row-start-1 md:row-start-auto overflow-hidden">
          <img
            src="/images/services/service_website.jpg"
            alt="web developement"
            className="h-full rounded_border lg:h-[22em] object-cover w-full" loading="lazy"
          />
        </div>
        <div className="w-full flex flex-col gap-8 row-start-2 md:row-start-auto">
          <div className="text-grp h-full">
            <h2 className="font-bold text-2xl">Website Development</h2>
            <p className="text-md text-[#D4D4D4] leading-loose mt-4">
              Every business needs an online identity, at JVEC,we move beyond
              giving our clients just an online identity to giving them one that
              captivates and sells itself at first sight.
              <br /> <br />
              We understand the need for a good impression when prospective
              customers interact with your website for the first time. We build
              for our clients, websites that are fun and easy to interact with,
              giving our clients the representation they deserve.
            </p>
          </div>
          <Link
            smooth={true}
            duration={500}
            to="/services/web"
            className="btn w-1/2 text-center font-bold py-4 rounded-md focus:bg-primary-color-500 duration-150 active:bg-blue-800"
          >
            Learn More
          </Link>
        </div>

        <div className="w-full flex flex-col gap-8 row-start-4 md:row-start-auto">
          <div className="text-grp h-full">
            <h2 className="font-bold text-2xl">Mobile App Development</h2>
            <p className="text-md text-[#D4D4D4] leading-loose mt-4">
              In today’s evolving world, billions of business transactions are
              done with the help of mobile devices. At JVEC, we build for our
              clients, highly responsive Apps that guarantee the speed customers
              need to carry out business transactions.
              <br />
              <br />
              From design to implementation, we pay attention to details to
              ensure we deliver an App that is effective and efficient.
            </p>
          </div>
          <Link
            smooth={true}
            duration={500}
            to="/services/mobile"
            className="btn w-1/2 text-center font-bold py-4 rounded-md focus:bg-primary-color-500 duration-150 active:bg-blue-800"
          >
            Learn More
          </Link>
        </div>
        <div className="flex justify-center items-center row-start-3 md:row-start-auto">
          <img src="/images/services/mobile.png" alt="digital marketing" loading="lazy" className="h-full lg:h-[22em] w-full rounded_border object-cover" />
        </div>
        <div className="flex justify-center items-center row-start-5 md:row-start-auto">
          <img
            src="/images/services/service_software.jpg"
            alt="web developement" loading="lazy"
            className="h-full lg:h-[22em] rounded_border object-cover w-full"
          />
        </div>
        <div className="w-full flex flex-col gap-8 row-start-6 md:row-start-auto">
          <div className="text-grp h-full">
            <h2 className="font-bold text-2xl">Software Development</h2>
            <p className="text-md text-[#D4D4D4] leading-loose mt-4">
            Elevate your business with our top-tier Software Development services. Our seasoned professionals deliver quality software solutions that drive growth and efficiency. 
<br/><br/>We create robust, user-friendly software that sets you apart in the market.
            </p>
          </div>
          <Link
            smooth={true}
            duration={500}
            to="/services/software"
            className="btn w-1/2 text-center font-bold py-4 rounded-md focus:bg-primary-color-500 duration-150 active:bg-blue-800"
          >
            Learn More
          </Link>
        </div>
        <div className="w-full flex flex-col gap-8 row-start-8 md:row-start-auto">
          <div className="text-grp h-full">
            <h2 className="font-bold text-2xl">Digital Marketing</h2>
            <p className="text-md text-[#D4D4D4] leading-loose mt-4">
            Successful businesses are not necessarily the ones with the best products but those that have the best marketing playbooks. At JVEC, we design effective marketing campaigns.
              <br />
              <br />
              We come up with the best stories for your products that are appealing, captivating, and potent enough to induce the necessary actions from your potential customers. Let’s drive the results that matter to you.
            </p>
          </div>
          <Link
            smooth={true}
            duration={500}
            to="/services/digital"
            className="btn w-1/2 text-center font-bold py-4 rounded-md focus:bg-primary-color-500 duration-150 active:bg-blue-800"
          >
            Learn More
          </Link>
        </div>
        <div className="flex justify-center items-center row-start-7 md:row-start-auto">
          <img src="/images/services/service_digital.jpg" loading="lazy" alt="Digital Marketing" className="h-full lg:h-[22em] rounded_border w-full object-cover" />
        </div>
        <div className="flex justify-center items-center row-start-9 md:row-start-auto">
          <img
            src="/images/services/productDesign.jpg"
            alt="Product Design" loading="lazy"
            className="h-full lg:h-[22em] rounded_border object-cover w-full"
          />
        </div>
        <div className="w-full flex flex-col gap-8 row-start-10 md:row-start-auto">
          <div className="text-grp h-full">
            <h2 className="font-bold text-2xl">Product Design</h2>
            <p className="text-md text-[#D4D4D4] leading-loose mt-4">
            Transform your ideas into exceptional products with our expert Product Design services. Our seasoned team of professionals delivers top-notch designs that merge innovation and functionality seamlessly. 
<br/><br/>Elevate your product’s potential with our proficiency in creating captivating, user-centric designs. Join us in crafting the next big thing in the world of technology.
            </p>
          </div>
          <Link
            smooth={true}
            duration={500}
            to="/services/product"
            className="btn w-1/2 text-center font-bold py-4 rounded-md focus:bg-primary-color-500 duration-150 active:bg-blue-800"
          >
            Learn More
          </Link>
        </div>
        <div className="w-full flex flex-col gap-8 row-start-12 md:row-start-auto">
          <div className="text-grp h-full">
            <h2 className="font-bold text-2xl">Graphics Design</h2>
            <p className="text-md text-[#D4D4D4] leading-loose mt-4">
            Transform your brand’s vision into captivating reality with our expert Graphic Design services. Our skilled team crafts visually stunning designs tailored to your unique identity. 
              <br />
              <br />
              Elevate your marketing success and make a lasting impression with our proficiency in professional graphics. Discover the art of visual excellence with us.
            </p>
          </div>
          <Link
            smooth={true}
            duration={500}
            to="/services/graphics"
            className="btn w-1/2 text-center font-bold py-4 rounded-md focus:bg-primary-color-500 duration-150 active:bg-blue-800"
          >
            Learn More
          </Link>
        </div>
        <div className="flex justify-center items-center row-start-11 md:row-start-auto">
          <img src="/images/services/graphicsDesign.jpg" loading="lazy" alt="Graphics Design" className="h-full lg:h-[22em] w-full rounded_border object-cover" />
        </div>
        <div className="flex justify-center items-center row-start-13 md:row-start-auto">
          <img
            src="/images/services/motion.jpg" loading="lazy"
            alt="Motion Design / Video Editing"
            className="h-full lg:h-[22em] object-cover rounded_border w-full"
          />
        </div>
        <div className="w-full flex flex-col gap-8 row-start-14 md:row-start-auto">
          <div className="text-grp h-full">
            <h2 className="font-bold text-2xl">Motion Design / Video Editing</h2>
            <p className="text-md text-[#D4D4D4] leading-loose mt-4">
            Unlock the power of captivating visuals with our expert Motion Design and Video Editing services. We bring your ideas to life with precision and creativity, delivering professional results that engage and inspire. 
<br/><br/>Trust in our proficiency to transform your content into dynamic, impactful visuals
            </p>
          </div>
          <Link
            smooth={true}
            duration={500}
            to="/services/motion"
            className="btn w-1/2 text-center font-bold py-4 rounded-md focus:bg-primary-color-500 duration-150 active:bg-blue-800"
          >
            Learn More
          </Link>
        </div>
        <div className="w-full flex flex-col gap-8 row-start-16 md:row-start-auto">
          <div className="text-grp h-full">
            <h2 className="font-bold text-2xl">Consulting</h2>
            <p className="text-md text-[#D4D4D4] leading-loose mt-4">
            We have a team of ICT specialists with working experience that spans decades. We give top-notch recommendations and advice that are guaranteed to work when implemented. 
              <br />
              <br />
              We proffer the solutions and we guide you through the implementation.
            </p>
          </div>
          <Link
            smooth={true}
            duration={500}
            to="/services/consulting"
            className="btn w-1/2 text-center font-bold py-4 rounded-md focus:bg-primary-color-500 duration-150 active:bg-blue-800"
          >
            Learn More
          </Link>
        </div>
        <div className="flex justify-center items-center row-start-15 md:row-start-auto">
          <img src="/images/services/service_consulting.jpg" alt="Consulting" loading="lazy" className="h-full lg:h-[22em] w-full rounded_border object-cover" />
        </div>
        <div className="flex justify-center items-center row-start-17 md:row-start-auto">
          <img
            src="/images/services/service_corp.jpg" loading="lazy"
            alt="Corporate Training"
            className="h-full lg:h-[22em] rounded_border object-cover w-full"
          />
        </div>
        <div className="w-full flex flex-col gap-8 row-start-18 md:row-start-auto">
          <div className="text-grp h-full">
            <h2 className="font-bold text-2xl">Corporate Training</h2>
            <p className="text-md text-[#D4D4D4] leading-loose mt-4">
            Unlock your team’s full potential with our expert corporate training services. We bring unrivaled proficiency to elevate your employees’ skills and boost productivity. 
<br/><br/>Experience cutting-edge training tailored to your needs. We train on all the skills found on our training page.
            </p>
          </div>
          <Link
            smooth={true}
            duration={500}
            to="/services/corporate"
            className="btn w-1/2 text-center font-bold py-4 rounded-md focus:bg-primary-color-500 duration-150 active:bg-blue-800"
          >
            Learn More
          </Link>
        </div>
        <div className="w-full flex flex-col gap-8 row-start-20 md:row-start-auto">
          <div className="text-grp h-full">
            <h2 className="font-bold text-2xl">Data Consultancy</h2>
            <p className="text-md text-[#D4D4D4] leading-loose mt-4">
            At JVEC, our seasoned analysts provide strategic insights, tailored solutions, and robust data security, empowering your business to make confident, data-driven decisions. 
              <br />
              <br />
              Let’s help you elevate your decision-making, drive innovation, and stay ahead in today's data-driven landscape.
            </p>
          </div>
          <Link
            smooth={true}
            duration={500}
            to="/services/data"
            className="btn w-1/2 text-center font-bold py-4 rounded-md focus:bg-primary-color-500 duration-150 active:bg-blue-800"
          >
            Learn More
          </Link>
        </div> 
        <div className="flex justify-center items-center row-start-19 md:row-start-auto">
          <img src="/images/services/service_data.jpg" alt="Data Consultancy" loading="lazy" className="h-full lg:h-[22em] w-full rounded_border object-cover" />
        </div>

        <div className="flex justify-center items-center row-start-21 md:row-start-auto">
          <img src="/images/services/project_mgt.jpg" loading="lazy" alt="Project Mgt" className="h-full lg:h-[22em] w-full rounded_border object-cover" />
        </div>

        <div className="w-full flex flex-col gap-8 row-start-22 md:row-start-auto">
          <div className="text-grp h-full">
            <h2 className="font-bold text-2xl">Project Management </h2>
            <p className="text-md text-[#D4D4D4] leading-loose mt-4">
            Your business needs experienced hands to help manage your projects and give you the best results. At JVEC Solutions, our team of Project Managers with  cutting-edge management skills are your getaway to effortless project excellence.
            <br>
            </br>
            <br>
            </br>
            We help you redefine your project and turn your aspirations into achievements 
            </p>
          </div>
          <Link
            smooth={true}
            duration={500}
            to="/services/project_management"
            className="btn w-1/2 text-center font-bold py-4 rounded-md focus:bg-primary-color-500 duration-150 active:bg-blue-800"
          >
            Learn More
          </Link>
        </div>

        <div className="w-full flex flex-col gap-8 row-start-24 md:row-start-auto">
          <div className="text-grp h-full">
            <h2 className="font-bold text-2xl">Product Management </h2>
            <p className="text-md text-[#D4D4D4] leading-loose mt-4">
            Maximize your business's potential with JVEC Solutions - your trusted partner in product management. Our experienced product managers work closely with you to develop effective product strategies that align with your business goals. We conduct thorough market research and competitor analysis to ensure your products meet customer needs and stand out from the competition. 
            <br>
            </br>
            <br>
            </br>
            We help you redefine your project and turn your aspirations into achievements 
            </p>
          </div>
          <Link
            smooth={true}
            duration={500}
            to="/services/product_management"
            className="btn w-1/2 text-center font-bold py-4 rounded-md focus:bg-primary-color-500 duration-150 active:bg-blue-800"
          >
            Learn More
          </Link>
        </div>

        <div className="flex justify-center items-center row-start-23 md:row-start-auto">
          <img src="/images/services/product_mgt.jpg" alt="Product Mgt" loading="lazy" className="h-full lg:h-[22em] w-full rounded_border object-cover" />
        </div>
        
        <div className="flex justify-center items-center row-start-25 md:row-start-auto">
          <img src="/images/services/predictive_a.jpg" alt="Predictive Analysis" loading="lazy" className="h-full lg:h-[22em] w-full rounded_border object-cover" />
        </div>

        <div className="w-full flex flex-col gap-8 row-start-26 md:row-start-auto">
          <div className="text-grp h-full">
            <h2 className="font-bold text-2xl">Predictive Analysis</h2>
            <p className="text-md text-[#D4D4D4] leading-loose mt-4">
            Propel your business to new heights through informed decision-making. At JVEC,  we empower you to embrace the future with our Predictive Analytics services designed to transform the way you strategize, operate, and thrive in the market. When you are subscribed to us, we are there to ensure that you implement the strategies we give you, and see the results you get in your  business. 
            <br>
            </br>
            <br>
            </br>
            We help you redefine your project and turn your aspirations into achievements 
            </p>
          </div>
          <Link
            smooth={true}
            duration={500}
            to="/services/predictive"
            className="btn w-1/2 text-center font-bold py-4 rounded-md focus:bg-primary-color-500 duration-150 active:bg-blue-800"
          >
            Learn More
          </Link>
        </div>

      </section>
      <section
        // ref={process}
        id="services-section"
        className="process min-h-[70svh] md:min-h-[70vh] mt-20 px-[clamp(1em,8vw,5em)]"
      >
        <h1 className="text-white text-[clamp(2em,6vw,3.5em)] text-center mt-8 mb-2 md:my-0">
          Our Process
        </h1>
        <p className="content font-medium text-[#D4D4D4]  text-center text-[clamp(0.8em,2vw,1.1em)]">
          At JVEC, we leave you with a lasting impression
        </p>
        <div className="flex justify-between mt-24 gap-5 flex-col md:flex-row">
          {processContent}
        </div>
      </section>
      <ContactUs className='px-[clamp(1em,8vw,5em)]' />
    </main>
  );
};

export default MainServices;
