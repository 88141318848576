import React from "react";
import Construction from "../components/Contruction";

const Internship = () => {
  return (
    <Construction/>
  );
};

export default Internship;
