const trainingTestimonials= [
    {
      image: "/images/testimonial_carousel/avatar1.webp",
      desc : "SME success story image",
      testimonial: "Few months ago, I saw an advert on Data Analytics Training posted by Social media influencer - Inneh Joseph on LinkedIn. The training paved way for me in my new job - the first task given to me as Head of HR was in Excel; when I opened the Excel file, I saw the first topic (filter) treated in my first class. I recommend JVEC to all HR and Admin personnel, you need proficiency in Excel to report to management.",
      author: "~ Olumide"
    },
    {
      image: "/images/testimonial_carousel/avatar2.webp",
      testimonial: "The learning experience was awesome; despite coming in 2 weeks late, I was carried along so well. For me, it’s the personalized teachings and the attention to detail (fundamentals) of the course. Also, their instructors are vastly experienced which is a big advantage to leverage for those going into the Tech space. I recommend JVEC to anyone looking for the best Tech training in town.",
      author: "~ Felix"
    },
    {
      image: "/images/testimonial_carousel/avatar3.jpeg",
      desc : "client success story image",
      testimonial: "Having the Data Analytics program (Power BI and SQL) with JVEC Solutions was truly impactful. The tutors are vastly experienced and very much successful in their chosen careers and they go an extra length in ensuring that everyone is carried along. I strongly recommend JVEC Solutions to anyone who wishes to be an expert in the Data Analytics field.",
      author: "~ Peace"
    },
    {
      image: "/images/testimonial_carousel/avatar4.jpg",
      desc : "student success story image",
      testimonial: "I took the JVEC' Digital Marketing training and it was all I needed to get started as a Digital Marketer. Packed with so much value, I went from barely any knowledge of digital marketing to placing highly-targeted ads on Facebook. I landed my first job as a Digital Marketer almost immediately. I can't thank JVEC Solutions enough. ",
      author: "~ Thelma"
    },
    {
      image: "/images/testimonial_carousel/avatar5.jpeg",
      desc : "student success story image",
      testimonial: "I started my journey in Data analytics with JVEC Solutions a few weeks ago and it’s been mind-blowing. I love how the trainers take their time to explain and answer questions. We gain maximum knowledge at a pocket-friendly fee. I’ve learned so many things I never knew how to do on Microsoft Excel and that’s exceptional for me!",
      author: "~ Ada"
    },
    {
      image: "/images/testimonial_carousel/avatar6.jpg",
      desc : "student success story image",
      testimonial: " After trying different Training institutes without success, I was delighted to learn that JVEC Solutions adopts a different approach. Not only do they have the depth of talent and experience in Microsoft Excel, Power BI, etc., but they also issue personal mentors who look after their students to reinforce what they have learned. ",
      author: "~ Osita"
    },
  
    // start of testimonial
    {
      image: "/images/testimonial_carousel/Richard.jpeg",
      desc : "student success story image",
      testimonial: "My training with JVEC Solutions was an eye-opener for me. I got so much knowledge for a little fee. I was able to learn and eventually build a career in digital marketing and web development. Today, I am a certified Web developer and Digital Marketer. Many thanks to JVEC Solutions.",
      author: "~ Richard"
    },
    {
      image: "/images/testimonial_carousel/Moses.jpeg",
      desc : "student success story image",
      testimonial: "Learning Website Development under the tutelage of JVEC Solutions was very rewarding. I recommend the organization to anyone willing to excel in this Tech-driven society; they are passionate about imparting knowledge.",
      author: "~ Moses"
    },
    {
      image: "/images/testimonial_carousel/Ufuoma.jpg",
      desc : "student success story image",
      testimonial: "Learning Data Analytics from JVEC Solutions is one of my best decisions in life. Today, with my knowledge of Excel and Power BI, I have proved my proficiency as a Data Analyst in several job roles. I am grateful to JVEC Solutions for giving me a strong foundation as a Data Analyst.",
      author: "~ Ufuoma"
    },
    {
      image: "/images/testimonial_carousel/Michael.jpg",
      desc : "student success story image",
      testimonial: "I began my journey as a Data Scientist with JVEC. My learning experience with JVEC Solutions during the cohort was nothing short of exceptional! The depth of knowledge and expertise shared by the trainers is remarkable. What sets JVEC apart is its dedication to providing practical insights and real-world skills that are immediately applicable. If you’re planning to go into Data Science, I recommend JVEC anytime, any day.",
      author: "~ Michael"
    },
    {
      image: "/images/testimonial_carousel/kayode.jpg",
      desc : "student success story image",
      testimonial: "Hi, I am Kayode – a product of JVEC Solutions. One of the things I love about the institute is the problem solving skills and ability to communicate information effectively. The instructors are accessible both online and offline with great teaching techniques. If you’re looking for where to learn, I will gladly recommend JVEC Solutions.",
      author: "~ Kayode"
    },
    {
      image: "/images/testimonial_carousel/ruth.jpg",
      desc : "student success story image",
      testimonial: "Before now, I have always seen Data Analytics as a big deal but with JVEC, it was made so easy. The facilitators are highly skilled at teaching, they made every class interesting and impactful. Now, analyzing Data with Microsoft Excel at work has become easy as a breeze.",
      author: "~ Ruth"
    }
  ]
  export default trainingTestimonials;