

 const testimonials= [
  {
    image: "/images/testimonial_carousel/avatar1.webp",
    desc : "SME success story image",
    testimonial: "Few months ago, I saw an advert on Data Analytics Training posted by Social media influencer - Inneh Joseph on LinkedIn. The training paved way for me in my new job - the first task given to me as Head of HR was in Excel; when I opened the Excel file, I saw the first topic (filter) treated in my first class. I recommend JVEC to all HR and Admin personnel, you need proficiency in Excel to report to management.",
    author: "~ Olumide"
  },
  {
    image: "/images/testimonial_carousel/avatar2.webp",
    testimonial: "JVEC Solutions is an ‘a-people-first company;’ they tailor their services to meet people’s specific needs.",
    author: "~ Felix"
  },
  {
    image: "/images/testimonial_carousel/avatar3.jpeg",
    desc : "client success story image",
    testimonial: "The Customer Support and relations at JVEC are second to none; they give premium support to customers’ challenges and go the extra mile to check on customer’s well-being.",
    author: "~ Peace"
  },
  {
    image: "/images/testimonial_carousel/avatar4.jpg",
    desc : "student success story image",
    testimonial: "I took the JVEC' Digital Marketing training and it was all I needed to get started as a Digital Marketer. Packed with so much value, I went from barely any knowledge of digital marketing to placing highly-targeted ads on Facebook. I landed my first job as a Digital Marketer almost immediately. I can't thank JVEC Solutions enough. ",
    author: "~ Thelma"
  },
  {
    image: "/images/testimonial_carousel/avatar5.jpeg",
    desc : "student success story image",
    testimonial: "I started my journey in Data analytics with JVEC Solutions a few weeks ago and it’s been mind-blowing. I love how the trainers take their time to explain and answer questions. We gain maximum knowledge at a pocket-friendly fee. I’ve learned so many things I never knew how to do on Microsoft Excel and that’s exceptional for me!",
    author: "~ Ada"
  },
  {
    image: "/images/testimonial_carousel/avatar6.jpg",
    desc : "student success story image",
    testimonial: " After trying different Training institutes without success, I was delighted to learn that JVEC Solutions adopts a different approach. Not only do they have the depth of talent and experience in Microsoft Excel, Power BI, etc., but they also issue personal mentors who look after their students to reinforce what they have learned. ",
    author: "~ Osita"
  }
]

export default testimonials;