import React from "react";

const socialIcons = [
  {
    path: "https://www.instagram.com/jvecsolutionsinc/",
    icon: (
      <img
        key="icon1"
        src="/images/footer/instagram.svg"
        alt="Instagram"
        className="w-5 h-fit"
      />
    ),
  },
  {
    path: "https://ng.linkedin.com/company/jvec-solutions-inc",
    icon: (
      <img
        key="icon2"
        src="/images/footer/linkedIn.svg"
        alt="LinkedIn"
        className="w-4 h-fit"
      />
    ),
  },
  {
    path: "https://twitter.com/jvec_solutions",
    icon: (
      <img
        key="icon3"
        src="/images/footer/x.svg"
        alt="X"
        className="w-4 h-fit"
      />
    ),
  },
  {
    path: "https://web.facebook.com/profile.php?id=100063505395740",
    icon: (
      <img
        key="icon4"
        src="/images/footer/facebook.svg"
        alt="Facebook"
        className="w-3 lg:w-3 h-fit"
      />
    ),
  },
];

export default socialIcons;
