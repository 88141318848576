const BootCampFaqsData = [
    {
      question: "How will the class be held?",
      answer:
        "Live video classes will be held on Google Meet, and the class will also be recorded for reference purposes. There will also be a Slack channel for class discussions.",
    },
    {
      question: "Is this course certified and how valid is the certificate?",
      answer:
        "At the end of the training, participants will be awarded a training certificate (an electronic certificate) after the completion of projects and final assessment. Our Certificate is valid; we’re duly registered with the Corporate Affairs Commission, Nigeria.",
    },
    {
      question: "Can I take multiple training classes?",
      answer: "No. You can only take one training; this is to help you streamline your focus.",
    },
    {
      question: "Is the training free?",
      answer: " Training is absolutely free of charge",
    },
    {
      question: "Am I expected to join both Morning and Afternoon classes?",
      answer: "No, you are expected to choose either Morning or Afternoon, this includes Saturday too.",
    },
  ];
  
  export default BootCampFaqsData;
  