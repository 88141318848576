import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import React, { useEffect, useRef, useState } from "react";
import ProcessCard from "./components/processCard";
import BuildComponent from "./components/buildSomething";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import serviceProcess from "./serviceProcess";

const Services = React.memo(() => {
  let content = useRef();
  let process = useRef();
  let [hasLoaded, setHasLoaded] = useState(false);
  const { service } = useParams();

  const jvecServices = {
    website: {
      body: "Embark on a transformative digital journey with JVEC, where we go beyond merely providing an online identity. We craft digital experiences that are easy to interact, captivating and effortlessly sell your brand from the first click. Our team blends technical mastery with creative brilliance, setting trends and redefining success in the modern world of web development. We make use of tailored programming language for the structuring. \nAt JVEC, innovation isn't a buzzword; it's our DNA. We're not content with following trends; we set them. With a toolkit encompassing the latest in responsive design and user experience, our developers, true professionals in their craft, transform visions into visually stunning, high-performing websites. Your partnership with JVEC isn't merely a service; it's an alliance that propels your digital success into uncharted territories. \nWhat sets JVEC apart is our strategic integration of cutting-edge technologies and avant-garde approaches. As pioneers in the industry, we provide businesses with a bespoke digital foundation tailored to their unique objectives. From inception to execution, our client-centric ethos ensures we don't just build websites; we cultivate relationships. Partner with JVEC, where innovation meets excellence, and every pixel tells the story of your success. \nUnleash the power of your online potential  by contacting us today for a free consultation and witness firsthand how JVEC can elevate your brand visibility and improve your customer satisfaction.",
      service: "Website Development",
      image: "/images/services/service_website.jpg"
    },
    mobile: {
      body: "Elevate your digital presence with cutting-edge mobile app development services from JVEC Solutions. As a leading ICT solutions company, we specialize in crafting bespoke mobile applications tailored to your unique business needs. \nOur team of seasoned developers combines innovation with expertise, ensuring that your app not only meets but exceeds industry standards. Experience seamless user interfaces, robust functionality, and a scalable architecture that propels your business into the digital forefront. \nReady to transform your ideas into reality?  Book a consultation call with JVEC Solutions today and unlock the full potential of mobile app innovation for your business. Our experts are eager to understand your goals, explore possibilities, and chart a tailored roadmap for your app development journey. \nAccelerate your digital transformation and stay ahead in the dynamic landscape of technology.",
      service: "Mobile App Development",
      image: "/images/services/mobile.png"
    },
    software: {
      body: "Your business is one-of-a-kind, and so should be your software. Our expert developers craft tailor-made solutions that align perfectly with your objectives, ensuring a seamless integration into your operations resulting in excellence on all fronts. \nAt JVEC Solutions, we don't just code, we craft solutions that transform your ideas into powerful, functional realities. Using the most efficient frameworks, we deliver your solution with finesse and professionalism that can't be easily rivaled. \nOur agile development methodology ensures swift progress, allowing you to witness tangible results sooner and adapt to changing requirements effortlessly. Prioritizing security we build for the future, building within your software, infrastructures to accommodate your growth. \nEnterprise Solutions such as scalable software suites designed to meet the unique challenges of your enterprise and emerging technologies such as AI, machine learning, and blockchain built into your software solutions is our forte. \nReady to upgrade your software suite? Contact us today and let's create  solutions that work.",
      service: "Software Development",
      image: "/images/services/service_software.jpg"
    },
    digital: {
      body: "In the ever-evolving landscape of digital marketing, JVEC Solutions stands as a trailblazer, offering an unmatched experience that transforms brand narratives into visual masterpieces. With a keen focus on data-driven strategies, cutting-edge tools, and an unwavering commitment to client success, JVEC emerges as a beacon of excellence in the competitive arena. \nOur seasoned team of digital marketing experts brings industry expertise to the forefront, crafting tailored strategies that align seamlessly with unique business objectives. We take pride in delivering not just campaigns but measurable growth, providing comprehensive analytics and insights for informed decision-making. \nJVEC's 100% customer satisfaction rate, a testament to our dedication to surpassing expectations, speaks volumes. Rather than relying on exaggerated marketing speak, we prioritize a results-driven approach that ensures clients witness real, transformative growth. Embark on a transformative digital journey with JVEC Solutions, where innovation, tailored solutions, and customer satisfaction converge to redefine success in the dynamic digital landscape.\nContact us today for a free consultation and witness firsthand how JVEC's data-driven approach can elevate your brand in the digital arena. Ignite your digital potential with JVEC Solutions – where measurable excellence meets innovation! ",
      service: "Digital Marketing",
      image: "/images/services/service_digital.jpg"
    },
    product: {
      body: "We get it, the design world can sound like a foreign language. But fear not! We're here to break down the barriers. No design babble or confusing terminology, just real talk. We'll guide you through the design journey with clarity and excitement. \nYour product dreams become our canvas and because when we enjoy what we do, your product gets the magic touch. It's not just about design, it's about crafting an experience. \nWe believe in a collaborative design journey, keeping you engaged from concept to completion, reflecting your brand identity and meeting business objectives. \nFrom user interface design to user experience design, our multidisciplinary team adapts its skills to deliver comprehensive design solutions, catering to the unique requirements of your project. We test and refine designs meticulously, resulting in visually appealing and highly functional products that hold their own in the market.",
      service: "Product Design",
      image: "/images/services/productDesign.jpg"
    },
    graphics: {
      body: "In a world driven by visuals, your brand's identity is more important than ever. At JVEC Solutions, we specialize in turning your visions into captivating graphics that speak volume about your brand and leave a lasting impact on your audience. \nOur expert graphics design team is ready to help you elevate your brand using the power of stunning and captivating graphics. We are available to help you unlock the power of storytelling. \nWe pride ourselves in our ability to turn ideas into creative masterpieces, taking our time to understand your brand's personality and values to create designs that resonate with your audience. We also take pride in our ability to deliver your project on time without compromising on quality. We offer high quality designs at budget-friendly prices to accommodate businesses of all sizes. \nWhether it's a stunning logo, eye-catching social media post or even a complete brand overhaul, we've got you covered",
      service: "Graphics Design",
      image: "/images/services/graphicsDesign.jpg"
    },
    motion: {
      body: "We are your go-to for exceptional motion graphics and video editing services. Our team of skilled professionals is committed to delivering captivating visuals that engage and impress. From stunning motion graphics for your website to polished videos for product launches, we have the expertise and cutting-edge equipment to bring your ideas to life. Our experienced video editors ensure seamless transitions, balanced colors, and optimal sound quality, crafting videos that effectively communicate your message. Stand out from the competition and elevate your brand with JVEC Solutions. Contact us today for a visual experience like no other.",
      service: "Motion Design",
      image: "/images/services/motion.jpg"
    },
    consulting: {
      body: "At JVEC,  we redefine excellence in the ever-evolving digital landscape. As your trusted ICT partner, we bring unparalleled B2B and B2C consultation services tailored to elevate your brand. Whether you're aiming for a captivating web design, seamless mobile app development, or an immersive UI/UX experience,  our team of experts is dedicated to turning your vision into a digital masterpiece. \nOur consultations revolutionizes your digital presence  and  ensures that every project aligns with your business objectives and captivates your target audience to transform your vision into a resounding digital success. \nJoin the ranks of satisfied clients who have witnessed transformative results with us. Our ICT consultation goes beyond the conventional, bringing strategic insights, technical expertise, and a passion for innovation to every project. Let's embark on a journey to redefine your digital presence and propel your brand to new heights. Contact us today and experience the synergy of creativity and technology!",
      service: "Consulting",
      image: "/images/services/service_consulting.jpg"
    },
    corporate: {
      body: "We at JVEC,  take pride in being your premier destination for transformative corporate training in the dynamic realms of ICT, project management, and beyond. Elevate your team's capabilities with our holistic training programs, covering a spectrum of services including web design, mobile app development, UI/UX, motion graphics, video editing, product management, project management, data analysis, to mention a few.\n Embark on a journey of digital mastery with our comprehensive corporate training programs for you and your team to gain end-to-end digital mastery.  Whether you seek expertise in crafting visually stunning websites, developing user-centric mobile applications, or diving into the intricacies of data analysis, our expert instructors guide your team through every step of the process.We equip your team with the tools and methodologies to deliver projects efficiently, ensuring success in every endeavor.Our expert-led training blend theoretical knowledge with practical insights, ensuring your team gains valuable skills that directly translate into real-world success,Contact us and let's chart a course toward success together!",
      service: "Corporate Training",
      image: "/images/services/service_corp.jpg"
    },
    data_consultancy: {
      body: "Our Data Consultancy goes beyond raw analysis. We turn your data into a strategic powerhouse by unraveling the stories within your datasets, providing strategic recommendations that drive tangible results. Transform your data from mere numbers into a powerful asset that fuels your business growth. \nFrom raw data ingestion to data analysis and data visualization we are there at the echelon of it all. \nOur consultancy is more than a service; it's a partnership. We collaborate closely with your team, ensuring that our data strategies seamlessly integrate into your operations. We believe in building lasting relationships grounded in shared success. \nOur strategic recommendations are not just numbers; they are the driving force behind tangible results, turning your raw data into a powerful asset that propels your business growth. Tailored to your unique objectives, our solutions cover everything from market trends to operational efficiency, ensuring that your data strategy aligns seamlessly with your business goals. Backed by industry expertise and technological mastery, we go beyond being data analysts. We are your tech-savvy strategists, guiding you through every step of the digital transformation.\nChart a New Course with Data-Driven Success. Experience Our Solutions. Start Today!",
      service: "Data Consultancy",
      image: "/images/services/service_data.jpg"
    },
    project_management: {
      body: "Your business needs experienced hands to help manage your projects and give you the best results. At JVEC Solutions, our team of Project Managers with  cutting-edge management skills are your getaway to effortless project excellence. \nWe help you redefine your project and turn your aspirations into achievements.\n As your strategic partners, we provide insights and direction to ensure your project reaches its full potential. We are available to help you grow your business without constraints. No more drowning in Gantt charts and RACI matrices,  we're here to simplify and demystify every project.\n Our services adapt to the evolving needs of your projects and organization.  We maximize your resources,  ensuring that every hour and available budget is utilized effectively to achieve optimal outcomes.\n",
      service: "Project Management",
      image: "/images/services/project_mgt.jpg"
    },
    product_management: {
      body: "Maximize your business's potential with JVEC Solutions - your trusted partner in product management. Our experienced product managers work closely with you to develop effective product strategies that align with your business goals. We conduct thorough market research and competitor analysis to ensure your products meet customer needs and stand out from the competition. \nFrom idea generation to product launch and beyond, our team provides real-time time guidance and support, leveraging industry-leading product management tools and methodologies to deliver products on time and within budget.\n We help you prioritize the features to build and we craft out your product roadmap with your business goals in mind, mitigating risks and getting you from either MVP to full fledged product or from a pre-existing product to a mind blowing one. Contact us today to learn more about our comprehensive product management services.",
      service: "Product Management",
      image: "/images/services/product_mgt.jpg"
    },
    predictive: {
      service: "Predictive Analysis",
      image: "/images/services/predictive_a.jpg",
      body: "Propel your business to new heights through informed decision-making. At JVEC,  we empower you to embrace the future with our Predictive Analytics services designed to transform the way you strategize, operate, and thrive in the market. When you are subscribed to us, we are there to ensure that you implement the strategies we give you, and see the results you get in your  business. \n \n What You Gain in 6 Months\n  • Customized Data Analysis: Tailored insights into your business data for targeted decision-making. \n • Real-Time Adaptation: Adjust strategies on the go based on evolving market dynamics. \n • Implementation Support: Hands-on assistance in integrating recommended strategies into your operations. \n • Early Results Assessment: Track initial outcomes and make agile adjustments for optimal performance."
    }
  };

  let filter = Object.keys(jvecServices).filter((e) => e.includes(service));
  let renderContent =
    filter.length > 0 ? jvecServices[filter[0]] : "Website Development";

  useEffect(() => {
    window.scrollTo(0, 0);

    gsap.registerPlugin(ScrollTrigger);
    let ctx = gsap.context(() => {
      gsap
        .timeline()
        .from("h1", {
          y: 500,
          opacity: 0,
          duration: 1,
        })
        .from(
          ".content",
          {
            y: 200,
            opacity: 0,
            userSelect: "none",
            duration: 1,
          },
          "-=0.2"
        ).from(
          ".read",
          {
            y: 200,
            opacity: 0,
            userSelect: "none",
            duration: 1,
          },
          "-=0.1"
        );
    }, content);
    gsap
      .timeline({
        scrollTrigger: {
          trigger: process.current,
          start: "-30% 90%",
          end: "top center",
          // markers: true
        },
      })
      .fromTo(
        process.current,
        { y: 200 },
        {
          y: 0,
          duration: 1.4,
          ease: "power1.inOut",
        }
      );

    return () => ctx.revert(); // cleanup
  }, [service]);

  const formattedBody = renderContent.body.split('\n').map((paragraph, index) => (
    <React.Fragment key={index}>
      {paragraph}
      <br />
      <br />
    </React.Fragment>
  ));

  function load() {
    setHasLoaded(true);
  }

  let processContent = serviceProcess.map((elem) => (
    <ProcessCard
      key={elem.num}
      num={elem.num}
      header={elem.header}
      body={elem.body}
    />
  ));

  return (
    <main className="w-full font-noto bg-bg-color responsivePadding">
      <section
        ref={content}
        className="flex flex-col md:flex-row min-h-[100svh] md:min-h-[90vh] items-center gap-20 md:gap-10 mt-20 md:mt-0"
      >
        <div className="textGrp">
          <p className="text-[#D4D4D4] font-normal">OUR SERVICES</p>
          <div className="overflow-hidden">
            <h1 className="text-white text-[clamp(1.5em,6vw,5em)] leading-tight mt-8 mb-2 md:my-0 lg:text-[clamp(2.5em,6vw,6em)]">
              {renderContent.service}
            </h1>
          </div>
          <div className="overflow-hidden">
            <input type="checkbox" id="read-more" className="hidden peer/readMore" />
            <p className="content font-medium text-[#D4D4D4] lg:max-w-[80%] text-[clamp(0.8em,2vw,1.1em)] mt-5 peer-checked/readMore:line-clamp-none line-clamp-4">
              {formattedBody}
            </p>
            <label for="read-more" className="read peer-checked/readMore:after:content-['less'] after:content-['more'] text-stone-300 text-sm font-normal mt-1">Read </label>
          </div>
          <div className="btn-holder mt-10 flex">
            <Link
              smooth={true}
              duration={500}
              to="/contact"
              className="btn text-center font-bold py-2 w-full md:max-w-[12em] rounded-md focus:bg-primary-color-500 duration-150 active:bg-blue-800"
            >
              Get Started
            </Link>
          </div>
        </div>
        {hasLoaded ? null : <div className="w-[45%]"></div>}
        <img
          src={`${renderContent?.image??"/images/service_hero.jpg"}`}
          alt="services offered"
          className="w-full rounded_border md:w-2/5 md:h-2/5 max-h-[30rem] object-cover lg:min-w-[30rem]"
          onLoad={load}
        />
      </section>

      <section
        ref={process}
        id="services-section"
        className="process min-h-[100svh] md:min-h-screen mt-20"
      >
        <h1 className="text-white text-[clamp(2em,6vw,3.5em)] text-center mt-8 mb-2 md:my-0">
          Our Process
        </h1>
        <p className="content font-medium text-[#D4D4D4]  text-center text-[clamp(0.8em,2vw,1.1em)]">
          At JVEC, we leave you with a lasting impression
        </p>
        <div className="flex justify-between mt-24 gap-5 flex-col md:flex-row">
          {processContent}
        </div>
        <BuildComponent />
      </section>
    </main>
  );
});
export default Services;
