const programsData = {
  graphics_design: {
    name: "Graphics Design",
    role: "Graphics Designer",
    imageURL: "/images/training/graphic_d.jpg",
    description:
      "Are you ready to turn your passion for design into a lucrative skill set in the ever-evolving world of technology? JVEC Solutions introduces our instructor-led Graphics Design course which aims to prepare you with the skills and experiences needed to excel in the world of technology. Our hands-on approach helps you master Adobe illustrator and Photoshop with lightning speed.\n\tOur Graphics design course is your ticket to unleashing your creativity and mastery of the art of visual communication.\n\tOur course is instructor-led which ensures that you learn from industry-leading professionals with a wealth of experience in Graphics Design.\n\tAt JVEC Solutions, we take you through real-world projects that help sharpen your skills. You also get to build a cutting-edge portfolio that speaks volume to your potential recruiters.\n\tYou also get to connect with a vibrant community of like minds which can help accelerate your growth as a Graphics Design.",
    schedule: {
      Morning: [{ subject: "", time: "10am – 12:30pm" }],
      Afternoon: [{ subject: "", time: "1:30pm – 4pm" }],
      WeekendMorning: [
        { subject: "", time: "10am – 1:30pm" },
      ],
      WeekendAfternoon: [
        { subject: "", time: "2:30pm - 5:30pm" },
      ],
    },
    duration: "3 Months",
    fromDate: "18th of December, 2023",
    toDate: "9th of March, 2024",
  },

  mobile_development: {
    name: "Mobile App Development",
    role: "Mobile Developer",
    imageURL: "/images/training/mobile.jpg",
    description:
      "Embark on a transformative journey as we empower you to master the art of mobile app development through our exceptional training programs. Our training goes beyond the ordinary, offering a holistic and purpose-driven approach that propels you into the forefront of the tech industry. Our mobile app development training isn't just about coding, it's about empowering you with the skills to shape the future of technology. Immerse yourself in hands-on experiences, guided by industry experts who are dedicated to nurturing your potential.\n\tOur comprehensive training covers essential technologies like Swift for iOS and Kotlin for Android, along with frameworks such as React Native and flutter.  With the ever-growing demand for skilled mobile app developers, this training equips you with the expertise sought after by top tech companies globally. Stay ahead of the curve and become an invaluable asset in this rapidly evolving field. \n\tReady to unleash your potential and shape the future of mobile technology? Enroll in the mobile app development training today and be prepared to exceed your own expectations!",
    schedule: {
      Morning: [{ subject: "", time: "10am – 12:30pm" }],
      Afternoon: [{ subject: "", time: "1:30pm – 4pm" }],
      WeekendMorning: [],
      WeekendAfternoon: []
    },
    duration: "4 Months",
    fromDate: "18th of December, 2023",
    toDate: "6th of April, 2024",
  },

  software_development: {
    name: "Software Development",
    role: "Software Developer",
    imageURL: "/images/training/software_d.jpeg",
    description:
      "We are the launchpad for your coding journey, a coding odyssey that transforms you into a software boss. Whether you're starting your journey or aiming for the next level, JVEC Solutions Training is your key to mastering software development.\n\tOur training programs are meticulously crafted to empower you with the latest industry skills and expertise.\n\tYou get transformed in a short duration by learning from seasoned industry professionals, gaining insights into cutting-edge software development trends. \n\tYou will be mastering HTML, CSS, JavaScript, and React for front-end development. For backend development, your course content includes NodeJS, Python, PHP, or C#. \n\tOther benefits includes;\n\t • Hands-On Experience: Dive into real-world projects and coding exercises, honing practical skills that set you apart in the competitive tech landscape.\n\t • Industry-Relevant Projects: Apply your knowledge through hands-on projects, bridging the gap between theory and real-world application. \n\t • Supportive Community: Join a collaborative environment with mentors and peers, offering support and encouragement throughout your learning.\n\t • Career Guidance: Receive insights on career paths, job readiness, and industry trends, ensuring you're well-prepared for success in software development.\n\t Explore our programs now and take a leap toward a dynamic career in coding. Rest assured, you're in great hands!",
    schedule: {
      Morning: [
        { subject: "Frontend", time: "10am – 12:30pm" },
        { subject: "Backend", time: "10am – 12:30pm" },
      ],
      Afternoon: [
        { subject: "Frontend", time: "1:30pm – 4pm" },
        { subject: "Backend", time: "1:30pm – 4pm" },
      ],
      WeekendMorning: [],
      WeekendAfternoon: []
    },
    duration: "4 Months",
    fromDate: "18th of December, 2023",
    toDate: "6th of April, 2024",
  },

  product_design: {
    name: "Product Design",
    role: "Product Designer",
    imageURL: "/images/training/product_d.jpeg",
    description:
      "Discover the art and science of product design through our tailored training programs. We at JVEC Solutions, are dedicated to equipping you with the skills and insights needed to excel in the dynamic field of product design.\n\tThis is where you unlock the secrets of crafting user experiences that captivate and interfaces that resonate and our product design class is the passport to the dynamic world of design innovation.\nYes, design can be a joy! Our design course buzzes with laughter, collaboration, and a supportive community. Because when you love what you create, it reflects in your designs.\n\tDuring your course with us, you will be building a professional portfolio to showcase your new experience to the world and enable your fast growth in the product design industry.\nDribble, Behance, and other websites will be your playground while Figma, Adobe XD, and Sketch will be your tools. Enroll now and let's get it started.",
    schedule: {
      Morning: [{ subject: "", time: "10am – 12:30pm" }],
      Afternoon: [{ subject: "", time: "1:30pm – 4pm" }],
      WeekendMorning: [
        { subject: "", time: "10am – 1:30pm" },
      ],
      WeekendAfternoon: [
        { subject: "", time: "2:30pm - 5:30pm" },
      ],
    },
    duration: "3 Months",
    fromDate: "18th of December, 2023",
    toDate: "9th of March, 2024",
  },

  digital_marketing: {
    name: "Digital Marketing",
    role: "Digital Marketer",
    imageURL: "/images/training/digital_m.jpg",
    description:
      "Embark on an empowering journey with our digital marketing courses. Where the demand for skilled professionals is unprecedented in today's dynamic landscape, enrolling with us isn't just an education choice, it's a strategic move towards a competitive edge.\n\tFrom establishing a strong foundation to advanced strategies in social media branding, SEO, and e-commerce, each module provides practical knowledge to navigate the complex landscape.\n\tLed by seasoned professionals, our courses provide real-world applications, allowing you to learn at your pace with installment payments. Access resources, templates, and open doors to lucrative career opportunities, all sealed with a prestigious JVEC certificate.\nSome of the course content includes:\n\t• Social Media Marketing\n\t• Email Marketing\n\t• Affiliate Marketing\n\t• Search Engine Optimization\n\t• Google Ads\n\t• Copywriting\n\t• Content writing\n\tUpon completing our course, you stand to gain:\n\t• Proficiency in SEO, e-commerce, and email marketing positions you for success in a digital-centric world.\n\t• Community and Support: Join a vibrant learning community, fostering networking opportunities, collaboration, and ongoing support.\n\nThe competitive edge isn't just about a certificate; it's about mastering skills that businesses urgently seek. Enroll with us today, stepping into a future where innovation meets expertise in the dynamic field of digital marketing.",
    schedule: {
      Morning: [{ subject: "", time: "10am – 12:30pm" }],
      Afternoon: [{ subject: "", time: "1:30pm – 4pm" }],
      WeekendMorning: [
        { subject: "", time: "10am – 1:30pm" },
      ],
      WeekendAfternoon: [
        { subject: "", time: "2:30pm - 5:30pm" },
      ],
    },
    duration: "3 Months",
    fromDate: "29th of January, 2024",
    toDate: "20th of April, 2024",
  },

  project_management: {
    name: "Project Management",
    role: "Project Manager",
    imageURL: "/images/training/project_m.jpg",
    description:
      "Our Project Management program equips you with the skills and strategies you need to navigate the complexities of project execution.\n\tOur expert team of Project Managers would take you through the process of project planning, execution, and delivery with our in-depth modules designed to cover every aspect of project management.\n\tYou also get to put your learning into practice through hands-on projects that will help you gain invaluable experience that sets you apart in the competitive project management landscape.\n\tOur instructor-led training familiarizes you with the latest project management tools and methodologies, staying ahead of industry trends.\n\tAt JVEC Solutions, we take you through the process of project planning and scheduling, risk management, Agile and scrum methodologies, communication strategies, and many more.",
    schedule: {
      Morning: [{ subject: "", time: "10am – 12:30pm" }],
      Afternoon: [{ subject: "", time: "1:30pm – 4pm" }],
      WeekendMorning: [
        { subject: "", time: "10am – 1:30pm" },
      ],
      WeekendAfternoon: [
        { subject: "", time: "2:30pm - 5:30pm" },
      ],
    },
    duration: "3 Months",
    fromDate: "18th of December, 2023",
    toDate: "9th of March, 2024",
  },

  cyber_security: {
    name: "Cyber Security",
    role: "Security Analyst",
    imageURL: "/images/training/cyber.jpg",
    description:
      "In the dynamic landscape of today's digital age, the call for cybersecurity expertise has reached unprecedented heights. Step into the forefront of cybersecurity with JVEC Solutions.\nOur Cyber Security program is more than a career choice; it's a strategic commitment to fortify global digital defenses.\n\tBy enrolling, you actively contribute to global digital resilience, becoming a guardian of the digital realm. With flexible payment options and a learner-centric approach, this program ensures accessibility, empowering all aspiring professionals to acquire crucial cybersecurity skills.\n\tCrafted to combat evolving cyber threats, the program provides a comprehensive overview, delving into Security Governance, Network Security, Cyber Attacks, Web Application Security, and Malware.\n\tNavigate the dynamic cybersecurity landscape effectively with JVEC Solutions' commitment to accessibility through flexible payment options and a learner-centric approach.\nArmed with expertise, you'll emerge as a highly sought-after professional, ready to make substantial contributions to the cybersecurity job market.\n\tSecure your future in cybersecurity, embrace the commitment to safeguarding our digital world today. Don't miss out; enroll now and become a cybersecurity guardian!",
    schedule: {
      Morning: [{ subject: "", time: "10am – 12:30pm" }],
      Afternoon: [{ subject: "", time: "1:30pm – 4pm" }],
      WeekendMorning: [
        { subject: "", time: "10am – 2pm" },
      ],
      WeekendAfternoon: [
        { subject: "", time: "12pm - 4pm" },
      ],
    },
    duration: "6 Months",
    fromDate: "29th of January, 2024",
    toDate: "13th of July, 2024",
  },

  digital_literacy: {
    name: "Digital Literacy",
    role: "Content Creator/Influencer",
    imageURL: "/images/training/digital_l.jpg",
    description:
      "The world is rapidly changing, and tech is at the forefront of these changes. Embark on a journey to master the digital tools that define the modern world.\n\tOur digital literacy course is designed to equip you with essential skills and knowledge across a wide range of digital platforms. Delve into the following key areas to enhance your digital prowess.\n\tNo matter what level of proficiency you are currently at, we ensure you grow in your computing mastery.\n\tHere are some of the courses under our digital literacy program (DLP) -\n\tOperating System and File Management: Windows OS, File Management.\n\tOffice Suite: Microsoft Word, Microsoft Excel, Microsoft PowerPoint.\n\tGoogle Workspace: Docs, Sheets, Slides.\n\tEmail Clients: Microsoft Outlook, Gmail, and Ymail.\n\tVideo Conferencing: Zoom, Meet, Teams, and Skype.\n\tCloud Storage: Google Drive, OneDrive, Dropbox.\n\tDigital Communication: Slack, WhatsApp, and Telegram.\n\tArtificial Intelligence: Chat GPT, Bard, Mid-Journey, Dall-E.\n\tPrivacy and Security Awareness: Enhance your understanding of system security, VPNs, 2FA (Two Factor Authentication).\n\tWith our flexible course structure, you have the freedom to explore each module independently. Additionally, we offer convenient payment plans in installments, making it easier for you to embark on this transformative learning journey.",
    schedule: {
      Morning: [{ subject: "", time: "10am – 12:30pm" }],
      Afternoon: [{ subject: "", time: "1:30pm – 4pm" }],
      WeekendMorning: [
        { subject: "", time: "10am – 1:30pm" },
      ],
      WeekendAfternoon: [
        { subject: "", time: "2:30pm - 5:30pm" },
      ],
    },
    duration: "1 Month (Every Month)",
    fromDate: "First Monday of the Month",
    toDate: "Last Friday of the Month",
  },

  wordpress: {
    name: "WordPress",
    role: "WordPress Developer",
    imageURL: "/images/training/wordpress.jpg",
    description:
      "WordPress stands as the cornerstone of modern web development, empowering individuals and businesses to craft stunning websites with unparalleled flexibility and functionality.\nOur comprehensive WordPress training program is meticulously crafted to equip you with the essential skills and knowledge to thrive in the digital landscape.\nThroughout the course, you'll delve into:\n\tUnderstanding the WordPress ecosystem and its diverse capabilities.\n\tCreating visually stunning and responsive websites through intuitive design tools.\n\tCustomizing and optimizing websites to suit diverse needs and preferences.\n\tIntegrating essential plugins and features to enhance website functionality.\n\tExploring best practices for website security, performance, and search engine optimization (SEO).",
    schedule: {
      Morning: [{ subject: "", time: "10am – 12pm" }],
      Afternoon: [{ subject: "", time: "1pm – 3pm" }],
      WeekendMorning: [],
      WeekendAfternoon: []
    },
    duration: "2 Months",
    fromDate: "18th of December, 2023",
    toDate: "10th of February, 2024",
  },

  bdm: {
    name: "Business Development Management",
    role: "Business Manager",
    imageURL: "/images/training/bdm.jpg",
    description:
      "Our Business Development Management program is meticulously designed to equip you with the essential skills and knowledge to thrive in the ever-evolving business world.\nThroughout the course, you'll delve into:\n\tStrategic market analysis and identification of growth opportunities.\n\tFormulating and executing effective business development strategies.\n\tCultivating strong client relationships and partnerships.\n\tNavigating negotiations and deal-making with finesse.\n\tHarnessing digital tools and platforms for business expansion.\n\tBusiness Development Management is the cornerstone of sustainable growth, empowering entrepreneurs and organizations to identify, nurture, and capitalize on new prospects.\n\tWith a focus on strategic planning, relationship building, and market expansion, this discipline paves the way for sustainable success in today's competitive landscape.\n\tEquip yourself with the strategic prowess to navigate growth opportunities, foster partnerships, and position your business for success.\n\tEmpower businesses, Seize Opportunities, Enroll Today.",
    schedule: {
      Morning: [{ subject: "", time: "10am – 12:30pm" }],
      Afternoon: [{ subject: "", time: "1:30pm – 4pm" }],
      WeekendMorning: [
        { subject: "", time: "10am – 1:30pm" },
      ],
      WeekendAfternoon: [
        { subject: "", time: "2:30pm - 5:30pm" },
      ],
    },
    duration: "3 Months",
    fromDate: "18th of December, 2023",
    toDate: "9th of March, 2024",
  },

  product_management: {
    name: "Product Management",
    role: "Product Manager",
    imageURL: "/images/training/product_m.jpg",
    description:
      "Our Product management program seamlessly integrates theoretical frameworks with hands-on projects, ensuring that you gain a comprehensive understanding of product management dynamics.\n\tProduct management serves as the linchpin between ideation, execution, and market success. It empowers visionaries to conceptualize, develop, and launch products that resonate with users, disrupt industries, and carve new pathways for business growth.\n\tWhat You'll Learn: Our immersive Product Management program is meticulously designed to equip you with the essential skills and knowledge to thrive in the realm of product innovation.\nThroughout the course, you'll delve into:\n\t - Navigating the product lifecycle from ideation to launch and beyond.\n\t - Crafting compelling product strategies aligned with user needs and market dynamics.\n\t - Harnessing data-driven insights to drive product optimization and iteration.\n\t - Fostering cross-functional collaboration for seamless product development.\n\t - Mastering the art of user-centric design and product experience enhancement and more.\nEquip yourself with the strategic acumen to drive product innovation, captivate audiences, and lead the charge in shaping the future of groundbreaking products. Enroll now!",
    schedule: {
      Morning: [{ subject: "", time: "10am – 12:30pm" }],
      Afternoon: [{ subject: "", time: "1:30pm – 4pm" }],
      WeekendMorning: [
        { subject: "", time: "10am – 1:30pm" },
      ],
      WeekendAfternoon: [
        { subject: "", time: "2:30pm - 5:30pm" },
      ],
    },
    duration: "3 Months",
    fromDate: "18th of December, 2023",
    toDate: "9th of March, 2024",
  },

  motion_graphics: {
    name: "Motion Graphics & Video Editing",
    role: "Motion Designer",
    imageURL: "/images/training/motion.jpg",
    description:
      "DaVinci Resolve, Adobe After Effects, Adobe Premiere Pro, FInal Cut Pro might seem like a mystery to you right now but rest assured, we demystify all these wonderful tools in our motion graphics and video editing course.\n\tMotion Graphics and Video Editing serve as the bedrock of immersive storytelling, enabling creators to weave captivating visual narratives, amplify brand messaging, and evoke powerful emotions through dynamic visuals. While learning with us you will be;\n\t- Mastering industry-leading video editing software and tools.\n\t- Crafting visually stunning motion graphics and animations.\n\t- Understanding the art of pacing, rhythm, and visual storytelling.\n\t- Harnessing the power of color grading and visual effects.\n\t- Navigating the convergence of art and technology for stunning visual productions.\n\tWe understand the diverse commitments of modern creators, which is why our training program offers flexible scheduling options to accommodate your creative journey. Whether you're a budding visual artist, a content creator, or an industry professional, our courses are tailored to fit seamlessly into your creative pursuits.\nWe can’t wait to see the magic you create, enroll now!",
    schedule: {
      Morning: [{ subject: "", time: "10am – 12:30pm" }],
      Afternoon: [{ subject: "", time: "1:30pm – 4pm" }],
      WeekendMorning: [
        { subject: "", time: "10am – 1:30pm" },
      ],
      WeekendAfternoon: [
        { subject: "", time: "2:30pm - 5:30pm" },
      ],
    },
    duration: "3 Months",
    fromDate: "18th of December, 2023",
    toDate: "9th of March, 2024",
  },

  data_analytics: {
    name: "Data Analytics",
    role: "Data Analyst",
    imageURL: "/images/training/data_a.jpg",
    description:
      "At JVEC, we're dedicated to empowering aspiring data analysts with the insights and tools needed to navigate the complexities of data-driven environments. Our program seamlessly integrates theoretical concepts with practical applications, ensuring that you gain a comprehensive understanding of data analytics dynamics and soon you will become a master in using tools like Tableau and Power BI\n\tWe understand the diverse commitments of modern professionals, which is why our training program offers flexible scheduling options to accommodate your learning journey. Whether you're a budding data enthusiast, a business professional, or an industry expert, our courses are tailored to fit seamlessly into your career aspirations.\n\tIn today's data-driven world, the ability to extract valuable insights from data is a critical skill. Data analytics empowers businesses and organizations to unravel patterns, trends, and correlations, fostering informed strategies and driving meaningful outcomes.\n\tOur comprehensive Data Analytics program is meticulously crafted to equip you with the essential skills and knowledge to thrive in the dynamic field of data analysis. Throughout the course, you'll learn :\n\t- Mastering data visualization and interpretation techniques.\n\t   - Utilizing statistical methods to derive meaningful conclusions.\n\t- Uncovering actionable insights to drive strategic decision-making.\n\t- Understanding the convergence of data science and business intelligence.\n\t- Navigating popular data analytics tools and platforms like;\n\t  - Microsoft Excel\n\t  - Power BI\n\t  - SQL\n\t  - Tableau\n\t  - Google Sheet",
    schedule: {
      Morning: [
        { subject: "Excel", time: "10am – 11am" },
        { subject: "Google Sheets", time: "10am – 11am" },
        { subject: "Power BI", time: "11:30am – 12:30pm" },
        { subject: "Tableau", time: "11:30am – 12:30pm" },
        { subject: "SQL", time: "11:30am – 12:30pm" },
      ],
      Afternoon: [
        { subject: "Excel", time: "12pm – 1pm" },
        { subject: "Google Sheets", time: "12pm – 1pm" },
        { subject: "Power BI", time: "1:30pm – 2:30pm" },
        { subject: "Tableau", time: "1:30pm – 2:30pm" },
        { subject: "SQL", time: "3pm – 4pm" },
      ],
      WeekendMorning: [
        { subject: "Excel", time: "10am – 1:30pm" },
        { subject: "Google Sheets", time: "10am – 1:30pm" },
        { subject: "SQL", time: "10am – 1:30pm" },
      ],
      WeekendAfternoon: [
        { subject: "Power BI", time: "12pm – 3:30pm" },
        { subject: "Tableau", time: "12pm – 3:30pm" },
      ],
    },
    duration: "3 Months",
    fromDate: "18th of December, 2023",
    toDate: "9th of March, 2024",
  },

  data_science: {
    name: "Data Science",
    role: "Data Scientist",
    imageURL: "/images/training/data_s.jpg",
    description:
      "Data analysis empowers businesses to extract actionable intelligence, predict trends, and optimize decision-making processes.\n\tOur immersive Data Science program is meticulously designed to equip you with the essential skills and knowledge to thrive in the dynamic landscape of data analysis and predictive modeling.\n\tThroughout the program, you will be learning:\n\t- NumPy\n\t- SciPy\n\t- Scikit-learn\n\t- Seaborn\n\t- Matplotlib\n\t- Pandas \n\nAt the end of the course, you should be able to carry out the following;\n\t- Statistical analysis and hypothesis testing.\n\t- Leveraging machine learning algorithms for predictive modeling.\n\t- Unraveling the nuances of data mining and exploratory data analysis.\n\t- Navigating the intricacies of big data and cloud-based analytics platforms.\n\t- Communicating insights and driving strategic decisions through data storytelling.\n\tAt JVEC, we are committed to nurturing aspiring data scientists and analysts, providing a comprehensive curriculum that seamlessly integrates theoretical foundations with real-world applications. Our hands-on approach empowers you to tackle real-world data challenges and emerge as a proficient data science practitioner.",
    schedule: {
      Morning: [{ subject: "", time: "10am – 12:30pm" }],
      Afternoon: [{ subject: "", time: "1:30pm – 4pm" }],
      WeekendMorning: [
        { subject: "", time: "10am – 1:30pm" },
      ],
      WeekendAfternoon: [
        { subject: "", time: "2:30pm - 5:30pm" },
      ],
    },
    duration: "4 Months",
    fromDate: "18th of December, 2023",
    toDate: "6th of April , 2024",
  },
};

export default programsData;
