import "./HeaderFooter.css";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import LinkList from "./components/LinkedList";
import ContactList from "./components/ContactList";
import SocialList from "./components/SocialList";
import socialIcons from "./components/SocialIcons";
import FooterLinkList from "./components/FooterLinkList";

const Footer = () => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <footer className="text-white text-center py-4 pt-10 pb-10 bg-[#0F0F0F] ">
      <div className="container mx-auto flex flex-col lg:flex-row justify-between items-start px-7 gap-10 ">
        {/* Solutions */}
        <div className="flex flex-col items-start gap-6 w-1/4">
          <Link to="/">
            <div className="group">
              <img
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                src={
                  isHovered
                    ? "/images/logo/logo_4k_x_2k_orange.png"
                    : "/images/logo/logo_4k_x_2k.png"
                }
                alt={
                  isHovered
                    ? "JVEC Solutions Logo - Orange"
                    : "JVEC Solutions Logo - Blue"
                }
                className="h-8 w-auto cursor-pointer"
              />
              <div className="hidden-link block md:hidden mt-5">
                <SocialList
                  icons={socialIcons}
                  descriptions={["Instagram", "LinkedIn", "X", "Facebook"]}
                />
              </div>
            </div>
          </Link>

          <div className="hidden md:flex items-center gap-2.5 ">
            <div className="text-justify hover:text-amber-500 text-white text-sm font-normal font-['Noto Sans'] leading-tight tracking-tight">
              © 2023 JVEC Solutions.
              <br /> All right reserved.
            </div>
          </div>
        </div>

        <div className="link-section flex justify-between w-full flex-col md:flex-row gap-14 md:gap-0">
          {/* Services */}
          <div className="service-training flex justify-between w-full">
            <div className="flex flex-col items-start gap-6">
              <div className="text-white pl-1 text-base font-bold font-['Noto Sans'] leading-normal tracking-wider ">
                SERVICES
              </div>
              <div className="flex flex-col gap-4">
                {/* Your service links */}
                <LinkList
                  items={[
                    "Website Development",
                    "Mobile App Development",
                    "Software Development",
                    "Digital Marketing",
                    "Product Design",
                    "Graphics Design",
                    "Motion Design / Video Editing",
                    "Consulting",
                    "Corporate Training",
                    "Data Consultancy",
                    "Product Management",
                    "Project Management",
                    "Predictive Analysis",
                  ]}
                  urls={[
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    "/services/data_consultancy",
                    "/services/product_management",
                    "/services/project_management",
                    undefined,
                  ]}
                />
              </div>
            </div>

            {/* Training */}
            <div className="flex flex-col items-start gap-6">
              <div className="text-white text-base pl-1 font-bold font-['Noto Sans'] leading-normal tracking-wider">
                TRAINING
              </div>
              <div className="flex flex-col gap-4">
                {/* Your training links */}
                <FooterLinkList
                  items={[
                    {
                      label: "Data Analytics",
                      path: "/training/data_analytics",
                    },
                    { label: "Data Science", path: "/training/data_science" },
                    { label: "WordPress", path: "/training/wordpress" },
                    {
                      label: "Product Design",
                      path: "/training/product_design",
                    },
                    {
                      label: "Project Management",
                      path: "/training/project_management",
                    },
                    {
                      label: "Product Management",
                      path: "/training/product_management",
                    },
                    {
                      label: "Graphics Design",
                      path: "/training/graphics_design",
                    },
                    {
                      label: "Motion Graphics / Video Editing",
                      path: "/training/motion_graphics",
                    },
                    {
                      label: "Software Development",
                      path: "/training/software_development",
                    },
                    {
                      label: "Mobile App Development",
                      path: "/training/mobile_development",
                    },
                    {
                      label: "Cyber Security",
                      path: "/training/cyber_security",
                    },
                    {
                      label: "Digital Literacy",
                      path: "/training/digital_literacy",
                    },
                    {
                      label: "Digital Marketing",
                      path: "/training/digital_marketing",
                    },
                    {
                      label: "Business Development Management",
                      path: "/training/bdm",
                    },
                  ]}
                />
              </div>
            </div>
          </div>

          <div className="about-contact flex justify-between md:justify-end w-full lg:gap-24 gap-14">
            <div className="flex flex-col items-start gap-6">
              {/* About */}
              <div className="text-white text-base font-bold pl-1 font-['Noto Sans'] leading-normal tracking-tight ">
                ABOUT
              </div>
              <div className="flex flex-col">
                {/* Your About links */}
                <FooterLinkList
                  items={[
                    { label: "About Us", path: "/about" },
                    { label: "Internship", path: "/internship" },
                    { label: "Training", path: "/training" },
                  ]}
                />
              </div>

              {/* More */}
              <div className="text-white text-base pl-1 font-bold font-['Noto Sans'] leading-normal tracking-wider">
                More
              </div>
              <div className="flex flex-col gap-4">
                {/* Your More links */}
                <FooterLinkList
                  items={[
                    { label: "Blog", path: "/blog" },
                    { label: "Portfolio", path: "/portfolio" },
                  ]}
                />
              </div>
            </div>

            <div className="flex flex-col items-start gap-6">
              {/* Contact */}
              <div className="flex flex-col items-start gap-6">
                <div className="text-white text-base pl-1 font-bold font-['Noto Sans'] leading-normal tracking-tight">
                  CONTACT US
                </div>
                <div className="flex flex-col gap-4">
                  {/* Use ContactList component for contact details */}
                  <ContactList
                    icon={
                      <img
                        src="/images/footer/call.svg"
                        alt="Icon"
                        className="w-4 h-4"
                      />
                    }
                    details="+234-0814-097-9054"
                    url="tel:+23408140979054"
                  />
                  {/* email */}
                  <ContactList
                    icon={
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_1208_1899)">
                          <path
                            d="M14.6693 4.00008C14.6693 3.26675 14.0693 2.66675 13.3359 2.66675H2.66927C1.93594 2.66675 1.33594 3.26675 1.33594 4.00008V12.0001C1.33594 12.7334 1.93594 13.3334 2.66927 13.3334H13.3359C14.0693 13.3334 14.6693 12.7334 14.6693 12.0001V4.00008ZM13.3359 4.00008L8.0026 7.33341L2.66927 4.00008H13.3359ZM13.3359 12.0001H2.66927V5.33341L8.0026 8.66675L13.3359 5.33341V12.0001Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1208_1899">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    }
                    details="hello@jvecsolutions.com"
                    url={"mailto:hello@jvecsolutions.com"}
                  />
                  {/* whatspp */}
                  <ContactList
                    url={
                      "https://api.whatsapp.com/send/?phone=23407080929897&text&type=phone_number&app_absent=0"
                    }
                    icon={
                      <img
                        src="/images/footer/whatsApp.svg"
                        alt="Icon"
                        className="w-4 h-4"
                      />
                    }

                    details="+234-0708-092-9897"
                  />
                  {/* address */}
                  <ContactList
                    icon={
                      <img
                        src="/images/footer/location.svg"
                        alt="Icon"
                        className="w-4 h-4"
                      />
                    }
                    details="12 Rev. Ogunbiyi Street,<br/> Off Oba Akinjobi, Ikeja GRA,<br />Lagos, Nigeria."
                    url="https://www.google.com/maps?q=12+Rev.+Ogunbiyi+Street,+Off+Oba+Akinjobi,+Ikeja+GRA,+Lagos,+Nigeria"
                  />
                </div>
              </div>

              {/* Social */}
              <div className="md:flex flex-col items-start gap-6 hidden">
                <div className="text-white text-base pl-1 font-bold font-['Noto Sans'] leading-normal tracking-tight">
                  Social
                </div>
                <div>
                  {/* Your Social links */}
                  <SocialList
                    icons={socialIcons}
                    descriptions={["Instagram", "LinkedIn", "X", "Facebook"]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copy-write md:hidden border-t-2 border-primary-color-500 mt-8 mx-14 pt-5">
        <div className="md:hidden flex">
          <div className="text-justify hover:text-amber-500 text-white text-sm font-semi-bold font-noto leading-tight tracking-tight">
            © 2023 JVEC Solutions. All right reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
