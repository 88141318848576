import React, { useEffect } from 'react';
import '../styles/Home.css';
import "../components/headerFooter/HeaderFooter.css";
import Typewriter from "typewriter-effect";
import Aos from 'aos';
import "aos/dist/aos.css";
import { Link } from 'react-router-dom';


const HeroPage = () => {

  useEffect(()=>{
  Aos.init({duration: 2000})
  
  },[])
  return (
    <header className="homepage_hero inner_padding grid_wrapper">
      <Typewriter
        className="hero_head"
        onInit={(typewriter) => {
          typewriter
            .typeString("<h1>First-class ICT Consultants.</h1>")
            .pauseFor(2500)
            .deleteAll()
            .start();
        }}
        options={{
          autoStart: true,
          loop: true,
          delay: 50,
          deleteSpeed: 10
        }}
      />

      <p className='px-9 hero_gap'>
      Elevating your Business through cutting-edge ICT Solutions
      </p>

      <Link
        to="services"
        smooth={true}
        duration={500}
        className="btn btn_lg"
        data-aos='fade-up' 
        data-aos-delay='100'
        data-aos-anchor-placement="top-bottom" 
      >
        <div className="button-text text-white text-sm font-bold font-['Noto Sans'] tracking-tight">Get Started</div>
      </Link>
    </header>
  );
};

export default HeroPage;
