import React from "react";
import { Collapse } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";

const { Panel } = Collapse;

function AppFaq({ faqsData }) {
  if (!faqsData) {
    console.log("no data");
    return null;
  }
  return (
    <div id="faq" className="block faqBlock lg:mx-[120px] mb-[50px] mt-[50px]  lg:mt-[100px]">
      <div className="container-fluid">
        <div className="titleHolder mb-[0px] lg:mb-[30px] lg:mt-[30px] text-center">
          <h1 className="text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl">
            Frequently Asked Questions
          </h1>
          <p>need answers? Find them here</p>
        </div>
        <div style={{ borderBottom: "1px solid white" }}>
          <Collapse
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) =>
              isActive ? (
                <MinusOutlined style={{ color: "white" }} />
              ) : (
                <PlusOutlined style={{ color: "white" }} />
              )
            }
            defaultActiveKey={["0"]}
            forceRender={true} 
            className="text-white"
          >
            {faqsData.map((faq, index) => (
              <Panel
                header={<h3 className="text-white">{faq.question}</h3>}
                key={index.toString()} // Use index as the key (you may want to use a more stable key)
              >
                <p className="text-white">{faq.answer}</p>
              </Panel>
            ))}
          </Collapse>
        </div>
        <div className="quickSupport mb-[30px] mt-[30px] text-center">
          <h3 className="mb-[20px]">Still Have Questions?</h3>
          <p className="text-xs">
            Can’t find the answer you’re looking for? Kindly contact{" "}
            <a href="/contact" className="text-blue-500">
              customer support
            </a>{" "}
            for a swift response.
          </p>
        </div>
      </div>
    </div>
  );
}

export default AppFaq;
