import React from "react";
import "../HeaderFooter.css";

const ContactList = ({ icon, details, url }) => (
  <div className="flex linkButton text-white footerlink text-left text-sm font-normal font-['Noto Sans'] leading-tight tracking-tight">
    <span className="mr-2">{icon}</span>
    {url ? (
      <a href={url} className="text-white" target="_blank" rel="noopener noreferrer">
        <span dangerouslySetInnerHTML={{ __html: details }} />
      </a>
    ) : (
      <span dangerouslySetInnerHTML={{ __html: details }} />
    )}
  </div>
);

export default ContactList;
