import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import NavBar from "./NavBar";
import "./HeaderFooter.css";
import MobileNavStateContext from "../../context/navContext";
import 'aos/dist/aos.css'

const Header = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const { setNavVisibility } = useContext(MobileNavStateContext);

  useEffect(() => {
    const handleScroll = () => {
      setScrollTop(window.scrollY);
      setShowScrollToTop(window.scrollY > 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollTop]);

  return (
    <header
      className={`text-white px-1 mt-3 font-sans fixed w-full z-40 ${showScrollToTop ? 'header-bg' : ''}`}
    >
      <div className="px-[clamp(1em,8vw,5em)] flex items-center justify-between">
        <Link to="/">
          <div
            className="group"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <img
              src={
                isHovered
                  ? "/images/logo/logo_4k_x_2k_orange.png"
                  : "/images/logo/logo_4k_x_2k.png"
              }
              alt={
                isHovered
                  ? "JVEC Solutions Logo - Orange"
                  : "JVEC Solutions Logo - Blue"
              }
              className="h-8 w-auto cursor-pointer"
            />
          </div>
        </Link>
        <NavBar />
        <Link
          data-aos="fade-in"
          data-aos-delay="100"
          data-aos-anchor-placement="top-bottom"
          data-aos-easing="ease-out-cubic"
          to="/contact"
          className="btn hide-button-mobile-view "
        >
          Contact Us
        </Link>
        <div
          className="hamburger p-2 cursor-pointer lg:hidden"
          onClick={() => setNavVisibility((state) => !state)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 20 20"
          >
            <path
              fill="white"
              fill-rule="evenodd"
              d="M3 5a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1Zm0 5a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1Zm6 5a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2h-6a1 1 0 0 1-1-1Z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
      </div>
    </header>
  );
};

export default Header;
