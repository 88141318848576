import React from 'react';
import Construction from '../components/Contruction';

const Blog = () => {
  return (
    <Construction />
  );
};

export default Blog;
