import { useEffect, useRef } from 'react';

function VideoPlayer() {
  const cloudinaryRef = useRef();
  const videoRef = useRef();

  useEffect(() => {
    if ( cloudinaryRef.current ) return;

    cloudinaryRef.current = window.cloudinary;
    cloudinaryRef.current.videoPlayer(videoRef.current, {
      cloud_name: 'dw94gbpfs'
    })
  }, []);

  return (
    <div>
      <video
        ref={videoRef}
        data-cld-public-id ="Jvec1_fravjq"
        fluid
        startOffset= '0'
        controls
      />
    </div>
  );
}

export default VideoPlayer;