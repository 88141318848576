import { useEffect } from "react";
import TrainingCard from "./component/trainingCard";
import Aos from "aos";
import "aos/dist/aos.css";
import TestimonialComponent from "./component/testimonialComponent";
import TrainingHero from "./component/TrainingHero";
import { Link } from "react-router-dom";
import VerticalCarousel from "../../components/verticalCarousel/VerticalCarousel";
import AppFaq from "./trainingPage/Faq";
import FaqsData from "./trainingPage/FaqData";

const Training = () => {
  const trainingCardInfo = [
    {
      title: "Software Development",
      body: "We are the launchpad for your coding journey coding odyssey that transforms you into a software boss. Whether you're starting your journey or aiming for the next level, JVEC Solutions Training is your key to mastering software development.  Our training programs are meticulously crafted to empower you with the latest industry skills and expertise. You get transformed in a short duration  by learning from seasoned industry professionals, gaining insights into cutting-edge software development trends.",
      img: "/images/training/thumbnail_software.png",
      url: "software_development"
    },
    {
      title: "Product Design",
      body: "Discover the art and science of product design through our tailored training programs. We at JVEC Solutions, are dedicated to equipping you with the skills and insights needed to excel in the dynamic field of product design.",
      img: "/images/training/thumbnail_prodct_d.png",
      url: "product_design"
    },
    {
      title: "Mobile App Development",
      body: "Embark on a transformative journey as we empower you to master the art of mobile app development through our exceptional training programs. Our training goes beyond the ordinary, offering a holistic and purpose-driven approach that propels you into the forefront of the tech industry. Our mobile app development training isn't just about coding,  it's about empowering you with the skills to shape the future of technology. Immerse yourself in hands-on experiences, guided by industry experts who are dedicated to nurturing your potential.",
      img: "/images/training/thumbnail_mobile.png",
      url: "mobile_development"
    },
    {
      title: "Digital Marketing",
      body: "Embark on an empowering journey with our digital marketing courses. Where the demand for skilled professionals is unprecedented in today's dynamic landscape,  enrolling with us isn't just an education choice,  it's a strategic move towards a competitive edge.",
      img: "/images/training/thumbnail_marketing.png",
      url: "digital_marketing",
    },
    {
      title: "Motion Graphics & Video Editing",
      body: "DaVinci Resolve, Adobe After Effects, Adobe Premiere Pro, FInal Cut Pro might seem like a mystery to you right now but rest assured, we demystify all these wonderful tools in our motion graphics and video editing course.",
      img: "/images/training/thumbnail_motion.png",
      url: "motion_graphics"
    },
    {
      title: "Graphics Design",
      body: "Are you ready to turn your passion for design into a lucrative skill set in the ever-evolving world of technology? JVEC Solutions introduces our instructor-led Graphics Design course which aims to prepare you with the skills and experiences needed to excel in the world of technology. Our hands-on approach helps you master Adobe illustrator and Photoshop with lightning speed.",
      img: "/images/training/thumbnail_graphics.png",
      url: "graphics_design"
    },
    {
      title: "Project Management",
      body: "Our Project Management program equips you with the skills and strategies you need to navigate the complexities of project execution. Our expert team of Project Managers would take you through the process of project planning,  execution and delivery with our in-depth modules designed to cover every aspect of project management.",
      img: "/images/training/thumbnail_project.png",
      url: "project_management"
    },
    {
      title: "Product Management",
      body: "Our Product management program seamlessly integrates theoretical frameworks with hands-on projects, ensuring that you gain a comprehensive understanding of product management dynamics. Product management serves as the linchpin between ideation, execution, and market success. It empowers visionaries to conceptualize, develop, and launch products that resonate with users, disrupt industries, and carve new pathways for business growth.",
      img: "/images/training/thumbnail_product_m.png",
      url: "product_management"
    },
    {
      title: "Cyber Security",
      body: "In the dynamic landscape of today's digital age, the call for cybersecurity expertise has reached unprecedented heights. Step into the forefront of cybersecurity with JVEC Solutions. Our Cyber Security program is more than a career choice, it's a strategic commitment to fortify global digital defenses. ",
      img: "/images/training/thumbnail_cyber.png",
      url: "cyber_security"
    },
    {
      title: "Digital Literacy",
      body: "The world is rapidly changing and tech is at the forefront of these changes. Embark on a journey to master the digital tools that define the modern world.  Our digital literacy course is designed to equip you with essential skills and knowledge across a wide range of digital platforms. Delve into the following key areas to enhance your digital prowess. No matter what level of proficiency you are currently at, we ensure you grow in your computing mastery.",
      img: "/images/training/thumbnail_literacy.png",
      url: "digital_literacy"
    },
    {
      title: "Wordpress",
      body: "​​WordPress stands as the cornerstone of modern web development, empowering individuals and businesses to craft stunning websites with unparalleled flexibility and functionality. With its user-friendly interface and robust customization options, WordPress offers an unparalleled canvas for unleashing your creativity and building impactful online presences.",
      img: "/images/training/thumbnail_wordpress.png",
      url: "wordpress"
    },
    {
      title: "Business Development Management",
      body: "​Our Business Development Management program is meticulously designed to equip you with the essential skills and knowledge to thrive in the ever-evolving business world. Business Development Management is the cornerstone of sustainable growth, empowering entrepreneurs and organizations to identify, nurture, and capitalize on new prospects.",
      img: "/images/training/thumbnail_bdm.png",
      url: "bdm"
    },
    {
      title: "Data Analytics ",
      body: " At JVEC, we're dedicated to empowering aspiring data analysts with the insights and tools needed to navigate the complexities of data-driven environments. Our program seamlessly integrates theoretical concepts with practical applications, ensuring that you gain a comprehensive understanding of data analytics dynamics and soon you will become a master in using tools like Tableau and Power BI.",
      img: "/images/training/thumbnail_analytics.png",
      url: "data_analytics"
    },
    {
      title: "Data Science ",
      body: "Data analysis empowers businesses to extract actionable intelligence, predict trends, and optimize decision-making processes. Our immersive Data Science program is meticulously designed to equip you with the essential skills and knowledge to thrive in the dynamic landscape of data analysis and predictive modeling.",
      img: "/images/training/thumbnail_science.png",
      url: "data_science"
    },
  ];
  const classFeaturesInfo = [
    {
      title: "Personal Mentoring",
      body: "Engage your Mentor at a personal level; learn and master industry best practices. Grow your career leveraging the experience of your mentor.",
      img: "/images/training/trainingMentor.svg",
    },
    {
      title: "Project Based",
      body: "Gain proficiency by working on highly-engaging projects for reinforced learning.",
      img: "/images/training/trainingLaptop.svg",
    },
    {
      title: "Flexible Classes",
      body: "Join our online classes from any part of the world. Classes are also recorded for reference purpose.",
      img: "/images/training/trainingClass.svg",
    },
  ];

  let cardComponent = trainingCardInfo.map((element, index) => (
    <TrainingCard
      key={element.title}
      title={element.title}
      body={element.body}
      img={element.img}
      url={element.url}
      className={
        index === trainingCardInfo.length - 1
          ? "md:col-span-full lg:col-span-1"
          : ""
      }
    />
  ));
  let classFeatures = classFeaturesInfo.map((element, index) => (
    <TrainingCard
      key={element.title}
      title={element.title}
      body={element.body}
      img={element.img}
      className={
        index === classFeaturesInfo.length - 1
          ? "md:col-span-full lg:col-span-1 lg:max-w-[20em] lg:justify-self-center"
          : "lg:max-w-[20em] lg:justify-self-center"
      }
      noButton
    />
  ));
  const features = [
    {
      icon: "/images/about_us/paid_internship_w.png",
      iconActive: "/images/about_us/paid_internship.png",
      title: "6-month paid Internship",
      description:
        "We provide a rigorous, paid 6-month internship program to equip our students for success in the industry.",
    },
    {
      icon: "/images/about_us/job_retention.png",
      iconActive: "/images/about_us/job_retention_b.png",
      title: "Job Retention",
      description:
        "We retain our outstanding interns to work fully with us and our sister companies.",
    },
    {
      icon: "/images/home/icons/star.png",
      iconActive: "/images/home/icons/star-blue.png",
      title: "500+ Trailblazers",
      description:
        "We have nurtured the brightest minds propelling innovation within the tech landscape, both in Africa and on a global scale.",
    },
    {
      icon: "/images/about_us/interview_prep.png",
      iconActive: "/images/about_us/interview_prep_b.png",
      title: "Interview Prep",
      description:
        "We equip our students for job interviews through structured mock interview sessions.",
    },
  ];
  const additionalFeaturesInfo = [
    {
      title: "Job Facilitation",
      body: "As a recruitment agency, we specialize in sourcing talent for external companies and successfully match our students with job opportunities in various roles",
      img: "/images/training/trainingPeople.svg",
    },
    {
      title: "LinkedIn Profile Optimization",
      body: "We help our students optimize their LinkedIn profile to help them stand out from the crowd and attract high-profile companies.",
      img: "/images/training/trainingPerson.svg",
    },
    {
      title: "CV and Cover Letter Writting",
      body: "We assist our students in creating compelling cover letters and CVs, enhancing their prospects of securing employment.",
      img: "/images/training/trainingMail.svg",
    },
  ];
  let additionalFeatures = additionalFeaturesInfo.map((element, index) => (
    <TrainingCard
      key={element.title}
      title={element.title}
      body={element.body}
      img={element.img}
      className={
        index === classFeaturesInfo.length - 1
          ? "md:col-span-full lg:col-span-1 lg:max-w-[20em] lg:justify-self-center"
          : "lg:max-w-[20em] lg:justify-self-center"
      }
      noButton
    />
  ));

  useEffect(() => {
    Aos.init({ duration: 900 });
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="min-h-screen major-background font-noto mb-20">
      <TrainingHero />
      <div className="flex items-center px-[clamp(1em,8vw,5em)] justify-center md:justify-between gap-10 mt-20 md:mb-48 md:gap-16 flex-col-reverse md:flex-row">
        <p className="text-[clamp(1em,2vw,1.6em)] w-full md:text-left text-center">
        At JVEC, we pride ourselves in tailor-made training; uniquely crafted to meet each student’s peculiar need. We pair each student with a Mentor which makes the learning experience personalized and highly effective. Our Mentors are Industry Experts who are generous enough to share their wealth of experience with our students.
        </p>
        <img
          src="./images/training/training_1.png"
          className="w-full md:w-[40%] rounded_border lg:[80%]"
          alt=""
        />
      </div>

      <div className="mt-20 mb-10 flex items-start md:items-center md:gap-x-10 lg:gap-0 justify-center px-[clamp(1em,8vw,5em)] h-fit md:flex-row flex-col">
        <div className="mt-16 md:mt-0 w-full flex text-center md:text-left justify-center md:items-start flex-col md:self-start">
          <h1 className="text-[30px] lg:text-[40px]">We are in a Class</h1>

          <p className="font-normal text-[15px] lg:text-[25px]">
            What separates us from the crowd
          </p>

          <div className="mt-10 flex w-full md:justify-start justify-center mb-20">
            <Link
              to="/"
              className="btn w-full text-center max-w-[15rem]"
            >
              Contact Us
            </Link>
          </div>
        </div>

        <div className="w-full md:max-w-[45vw] lg:max-w-[50vw] md:self-start">
          <VerticalCarousel features={features} />
        </div>
      </div>

      <section
        className="px-[clamp(1em,8vw,5em)] mt-20 min-h-[75vh] flex flex-col justify-center"
      >
        <h1 className="text-white text-[clamp(1.9em,7vw,3.2em)] text-center mt-8 mb-2 md:my-0">
          Why Choose Us
        </h1>

        <p className="content font-normal text-[#D4D4D4]  text-center text-[clamp(0.7em,3vw,1em)]">
          Experience The Best Training In Town
        </p>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6 mt-16 lg:justify-self-center">
          {classFeatures}
        </div>
      </section>

      <section
        className="px-[clamp(1em,8vw,5em)] mt-20 min-h-[75vh] flex flex-col justify-center"
        
      >
        <h1 className="text-white text-[clamp(1.9em,7vw,3.2em)] text-center mt-8 mb-2 md:my-0">
          We Go The Extra Mile
        </h1>

        <p className="content font-normal text-[#D4D4D4]  text-center text-[clamp(0.7em,3vw,1em)]">
          Additional value we offer for free
        </p>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6 mt-16">
          {additionalFeatures}
        </div>
      </section>
      <TestimonialComponent />

      <section
        className="px-[clamp(1em,8vw,5em)] mt-20 min-h-[75vh] flex flex-col justify-center"
      >
        <h1 className="text-white text-[clamp(1.9em,7vw,3.2em)] text-center mt-8 mb-2 md:my-0">
          Join a Cohort
        </h1>

        <p className="content font-normal text-[#D4D4D4]  text-center text-[clamp(0.7em,3vw,1em)]">
          your dream career is a cohort away
        </p>
        <div className="text-center mt-10 text-lg flex flex-col gap-4">
          <p>As a premier training institute, our unwavering commitment is to
            empower our students to soar to new heights in their careers. Over
            the past 6 years, we’ve successfully nurtured and developed more
            than 500 individuals who are now emerging as influential leaders in
            the dynamic Tech landscape of Africa and the global arena.
          </p>
          <p>What sets us apart is the invaluable opportunity our students have
            to be personally guided and mentored by industry experts with a
            wealth of experience amassed over a decade. This mentorship is an
            invaluable asset as it allows our students to tread the path to
            success with the wisdom and experience of those who have gone before
            them, propelling them to remarkable strides in their careers.
          </p>
          <p>Furthermore, as advocates for talent cultivation, our students
            undergo an intensive, paid 6-month internship program, from which we
            select and retain the most exceptional talents to join our team or
            one of our affiliated sister companies.
          </p>
          <p>Our students also benefit from being part of a vibrant and expansive
            community, where they can network with seasoned professionals who
            have made their mark in leading tech companies such as Bolt,
            Flutterwave, Kuda, Andela, Jumia, and more.
          </p>
          <p>Your dream career is within reach, and it’s just one cohort away.
            Take the first step toward your future by selecting any of the
            cohorts below and submitting your application today
          </p>
        </div>
      </section>

      <section className="px-[clamp(1em,8vw,5em)] mt-20">
        <h1 className="text-white text-[clamp(1.9em,7vw,3.2em)] text-center mt-8 mb-2 md:my-0">
          Training Courses
        </h1>
        <p className="content font-normal text-[#D4D4D4]  text-center text-[clamp(0.7em,3vw,1em)]">
          At JVEC, we leave you with a lasting impression based off our great
          tutors
        </p>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6 mt-16">
          {cardComponent}
        </div>
      </section>

      <section className="px-[clamp(1em,8vw,5em)] mt-20">
        <div className=" border-[1px] border-primary-color-200 px-10 rounded-md">
        
          <AppFaq faqsData={FaqsData} />
        </div>
      </section>

      <div className="mt-10 flex w-full justify-center mb-20">
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to="https://forms.gle/fjNU4BXFwG6Cf3FZ6"
              className="btn w-full text-center max-w-[15rem]"
            >
              Apply
            </Link>
          </div>
    </main>
  );
};

export default Training;
