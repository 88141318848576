import React, { useEffect, useState } from 'react'
// import TypewriterTraining from "typewriter-effect";

const TrainingHero = () =>  {

    const [wordIndex, setWordIndex] = useState(0);
    const [text, setText] = useState('');
    const [isDeleting, setIsDeleting] = useState(false);


    // const [currentSubheadingIndex, setCurrentSubheadingIndex] = useState(0);

    useEffect(() => {
      const subheadings = [
        'Learn from Industry Experts',
        'Attract high-profile Companies',
        'Start your Dream Career',
      ];

      function typing() {
        // Current word
        const currentWord = subheadings[wordIndex];
        // Determine the function to be performed
        const shouldDelete = isDeleting ? 1 : -1;
        // Create the new text
        setText(current => currentWord.substring(0, current.length - shouldDelete));
        // Determine if this word is complete
        if (!isDeleting && text === currentWord) {
          // Make a pause at the end
          setTimeout(() => setIsDeleting(true), 500);
        } else if (isDeleting && text === '') {
          setIsDeleting(false);
          // Move to the next word
          setWordIndex((current) => (current + 1) % subheadings.length);
        }
      }
    
      const timer = setTimeout(typing, isDeleting ? 50 : 50);
      // Cleanup function to clear the timeout
      return () => clearTimeout(timer);
    // Add dependencies to the dependency array
    }, [wordIndex, isDeleting, text]);

  return (
    <header className="lg:min-h-screen pt-20 flex justify-center items-center">
        <div className="w-full relative min-h-[40vh] md:min-h-[80vh] h-[30vh] bg-cover bg-center bg-[url(/public/images/training/trainingHero.png)] flex justify-center items-center flex-col px-[clamp(1em,8vw,5em)]">
          <h1 className="header_text text-[clamp(2.7em,6vw,4.5em)] max-w-[95%] md:max-w-[70%] lg:max-w-[55%] lg:pt-60 pt-10 text-center">
          Get Bespoke Training
          </h1>
          <h3 className="font-normal text-[clamp(0.9em,2vw,1.25em)] text-center">
          {text}
        </h3>
        </div>
      </header>
  );
};
export default TrainingHero;

