import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './VerticalCarousel.css';

const VerticalCarousel = ({ features }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    vertical: true,
    verticalSwiping: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    focusOnSelect: true,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    afterChange: (index) => setActiveIndex(index),
    responsive: [
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
    ],
  };

  return (
    <Slider {...settings} className='slide_container'>
      {features.map((feature, index) => (
        <div key={index} className={`vertical_item ${index === activeIndex ? 'slick-active' : ''}`}>
          <div className="slide_content">
            <img
              src={index === activeIndex ? feature.iconActive : feature.icon}
              alt={`Feature ${index}`}
              className='icon'
            />

            <div>
              <h3>{feature.title}</h3>
              <p>{feature.description}</p>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default VerticalCarousel;
