import ProgramInfo from "./trainingPage/ProgramInfo";

export const SelectedTraining = () => {
    return (
        <div className=' grid w-full flex'>
          <ProgramInfo/>
      </div>
      
      
    );
};
export default SelectedTraining;