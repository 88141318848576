import React, { useEffect } from 'react'
import './Section.css'
import Aos from 'aos';
import 'aos/dist/aos.css'


const SectionItem = ({imageUrl, headerText, hasLink, paragraphText, linkUrl }) => {

  useEffect(()=>{
    Aos.init({duration:1500})
  },[])
  return (
    <main className={hasLink? "section_wrapper section_bg" : "section_wrapper"}>
      <div className='container flex_wrapper flex_apart'>
        <div className="text_col">
          <h3 data-aos="fade-down" data-aos-delay="100" data-aos-offset="100">{headerText} </h3>
          <p data-aos="fade-up" data-aos-delay="100">{paragraphText}</p>
          {hasLink && (<a href={linkUrl} className='btn_arr'>Read more &rarr; </a>)}
        </div>
        <div className="image_col">
          <img src={imageUrl} alt="3D-globe-ball" />
        </div>
      </div>
    </main>
  )
}

export default SectionItem